import React from "react";
import { NavLink } from "react-router-dom";
import { MenuItem, useTheme } from "@mui/material";

export function HeaderMenuItems(props: any) {
  const { items, handleClose, itemColor } = props;
  const theme = useTheme();

  const styles = {
    menuItem: {
      borderRadius: "6px",
      [theme.breakpoints.up("lg")]: {
        padding: "6px 10px"
      },
    },
  };
  
  const menuItems = items.map((item: any) => (
    <MenuItem
      key={item.menuTitle}
      onClick={handleClose}
      disableRipple
      sx={styles.menuItem}
    >
      <NavLink
        to={item.path}
        className={({ isActive }) =>
          isActive
            ? "nav-link active-nav-link"
            : itemColor
            ? "nav-link mobile-nav-link"
            : "nav-link"
        }
      >
        {item.menuTitle}
      </NavLink>
    </MenuItem>
  ));
  return menuItems;
}
