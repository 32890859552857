import React, { ChangeEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { changeSettings } from "@features/portfolio/models";
import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";

export function PortfolioPagination() {
  const settings = useSelector((state: ApplicationState) => {
    return {
      pageSize: state.reducers.portfolio.settings.pageSize,
      pageCount: state.reducers.portfolio.settings.pageCount,
      pageIndex: state.reducers.portfolio.settings.pageIndex,
    };
  });
  const dispatch = useDispatch();

  const itemsPerPage = [3, 9, 18, 36];

  useEffect(() => {
    dispatch(changeSettings({ pageIndex: 1 }));
  }, [dispatch, settings.pageSize]);

  const pageIndexChange = (event: ChangeEvent<unknown>, value: number) => {
    dispatch(changeSettings({ pageIndex: value }));
  };
  const pageSizeChange = (event: SelectChangeEvent) => {
    dispatch(changeSettings({ pageSize: parseInt(event.target.value) }));
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Select
        labelId="pageSize-select-label"
        id="pageSize-select"
        defaultValue={String(itemsPerPage[1])}
        value={String(settings.pageSize)}
        onChange={pageSizeChange}
      >
        {itemsPerPage.map((item: number) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
      <Stack spacing={2}>
        <Pagination
          count={settings.pageCount}
          page={settings.pageIndex}
          onChange={pageIndexChange}
        />
      </Stack>
    </Box>
  );
}
