import React from "react";
import achievements from "@data/achievements.json";
import { Box, Grid, useTheme } from "@mui/material";

type AchievementType = {
  title: string;
  value: number | string;
  img: string;
};

function Achievement(props: AchievementType) {
  const { title, value, img } = props;
  const theme = useTheme();

  const styles = {
    wrapper: {
      width: "100%",
      boxShadow: "2px 2px 18px 5px rgba(34, 60, 80, 0.2);",
      padding: "15px",
      boxSizing: "border-box",
    },
    inner: {
      display: "flex",
      border: "1px solid #cccccc",
      overflow: "hidden",
    },
    contentBox: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      padding: "0 8px",
    },
    title: {
      textAlign: "center",
      fontWeight: 700,
    },
    value: {
      textAlign: "center",
      fontSize: { xs: "1.8rem", xl: "2.5rem" },
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    img: {
      maxWidth: "40%",
      width: "100%",
      height: "120px",
      objectFit: "cover",
      margin: 0,
    },
  };

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.inner}>
        <Box
          component="img"
          src={`/static/achievements/${img}`}
          alt={`Иконка ${title}`}
          sx={styles.img}
        ></Box>
        <Box sx={styles.contentBox}>
          <Box sx={styles.value}>{value}</Box>
          <Box sx={styles.title}>{title}</Box>
        </Box>
      </Box>
    </Box>
  );
}

export function Achievements() {
  const styles = {};

  return (
    <Grid container spacing={2}>
      {achievements.items.map((item) => {
        return (
          <Grid item xs={12} sm={6} md={6} lg={3} key={item.id}>
            <Achievement title={item.title} value={item.value} img={item.img} />
          </Grid>
        );
      })}
    </Grid>
  );
}
