import React from "react";
// import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "react-image-lightbox/style.css";
import { Grid, Typography, useTheme } from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";
// import {
//   lightBoxImgIdAction,
//   lightBoxModalToggleAction,
// } from "@components/lightbox";
import services from "@data/services.json";
import { Carousel } from "@components/carousel";
import { Subscribe } from "@components/subscribe/Subscribe";

export const ServicesDetail = () => {
  // const isOpen = useSelector(
  //   (state: ApplicationState) => state.reducers.lightbox.isOpen
  // );
  // const dispatch = useDispatch();
  const theme = useTheme();
  const { id } = useParams();

  const styles = {
    infoBox: {
      pl: "30px",
      [theme.breakpoints.down("md")]: {
        pl: 0,
        pt: "20px",
      },
    },
    name: {
      color: theme.palette.primary.dark,
      fontSize: theme.typography.h3,
      display: "block",
      fontWeight: 700,
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    mobileName: {
      color: theme.palette.primary.dark,
      fontSize: theme.typography.h3,
      textDecoration: "underline",
      display: "none",
      fontWeight: 700,
      [theme.breakpoints.down("md")]: {
        display: "block",
      },
      marginBottom: "15px",
    },
    title: {
      p: "10px 0",
      fontSize: theme.typography.h4,
      fontWeight: 700,
      display: "block",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    mobileTitle: {
      p: "10px 0",
      fontSize: theme.typography.h4,
      fontWeight: 700,
      display: "none",
      mb: "15px",
      [theme.breakpoints.down("md")]: {
        display: "block",
      },
    },
    text: {
      mt: "20px"
    },
    price: {
      color: theme.palette.primary.dark,
      fontSize: theme.typography.h4,
      fontWeight: 900,
      m: "0 8px",
    },
  };

  const curentItem = services.find(
    (item: Services.ItemTypes) => item.id === parseInt(id || "0")
  );
  const imagesList = curentItem ? curentItem.images : [];

  // const lightBoxToggle = useCallback(
  //   (id: number) => {
  //     dispatch(lightBoxImgIdAction(id));
  //     dispatch(lightBoxModalToggleAction(!isOpen));
  //   },
  //   [dispatch]
  // );

  const stages = curentItem?.stages?.map((item) => (
    <Grid
      key={item.id}
      sx={{
        display: "flex",
        alignItems: "center",
        marginBottom: "8px",
        maxWidth: "300px",
        [theme.breakpoints.down("md")]: {
          maxWidth: "100%",
        },
        width: "100%",
      }}
    >
      <Typography
        variant="body1"
        sx={{
          display: "flex",
          width: "100%",
          maxWidth: "24px",
          height: "100%",
          maxHeight: "24px",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          backgroundColor: theme.palette.primary.dark,
          color: theme.palette.primary.contrastText,
          marginRight: "10px",
        }}
      >
        {item.id + 1}
      </Typography>
      <Typography variant="body1">{item.name}</Typography>
    </Grid>
  ));

  const materials = curentItem?.materials?.map((item) => (
    <Typography key={item.id} sx={{ display: "flex", alignItems: "center" }}>
      <AddCircleOutline
        fontSize="small"
        htmlColor={theme.palette.primary.dark}
        sx={{ marginRight: "5px" }}
      />{" "}
      {item.name}
    </Typography>
  ));

  return (
    <>
      <Grid container>
        <Grid>
          <Typography variant="body1" sx={styles.mobileName}>
            {curentItem && curentItem.title}
          </Typography>
        </Grid>
        <Grid item md={6} xs={12} sx={{ position: "relative" }}>
          {curentItem && (
            <>
              <Carousel
                images={imagesList}
                type={"services"}
                isLightBox={true}
              />
              <Subscribe />
            </>
          )}
        </Grid>
        <Grid item md={6} xs={12} sx={styles.infoBox}>
          <Typography variant="body1" sx={styles.name}>
            {curentItem && curentItem.title}
          </Typography>
          <Typography variant="body1" sx={styles.text}>
            {curentItem && curentItem.text}
          </Typography>
        </Grid>
      </Grid>

      {stages && (
        <>
          <Typography
            variant="h5"
            sx={{ mb: "10px", fontWeight: 700, marginTop: "15px" }}
          >
            Этапы строительства
          </Typography>
          <Grid sx={{ display: "flex", flexWrap: "wrap" }}>{stages}</Grid>
        </>
      )}
      {materials && (
        <>
          <Typography
            variant="h5"
            sx={{ mb: "10px", fontWeight: 700, marginTop: "15px" }}
          >
            Необходимые материалы
          </Typography>
          {materials}
        </>
      )}
    </>
  );
};
