import React from "react";
import { Avatar, Link, Stack, styled, useTheme } from "@mui/material";
import { Phone, Email, Telegram, WhatsApp } from "@mui/icons-material";
import { orange } from "@mui/material/colors";
import appInfo from "@data/app-info.json"

const StyledLink = styled(Link)({
  display: "flex",
  alignItems: "center",
})

export function Messengers() {
  const theme = useTheme();
  return (
    <Stack direction="row" spacing={2}>
      <Avatar sx={{ bgcolor: "#0088cc", width: "35px", height: "35px" }}>
        <StyledLink
          href={`http://t.me/${appInfo.contacts.messengers[0].name}`}
          color={"#fff"}
        >
          <Telegram fontSize="small" />
        </StyledLink>
      </Avatar>
      <Avatar sx={{ bgcolor: "#43d854", width: "35px", height: "35px" }}>
        <StyledLink
          href={`https://wa.me/${appInfo.contacts.messengers[1].whatsApp}`}
          color={"#fff"}
        >
          <WhatsApp fontSize="small" />
        </StyledLink>
      </Avatar>
      <Avatar sx={{ bgcolor: orange[500], width: "35px", height: "35px" }}>
        <StyledLink
          type="mail"
          href={`mailto:${appInfo.contacts.messengers[3].email}`}
          color={"#fff"}
        >
          <Email fontSize="small" />
        </StyledLink>
      </Avatar>
      <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "35px", height: "35px" }}>
        <StyledLink href={`tel:${appInfo.contacts.phone}`} color={"#fff"}>
          <Phone fontSize="small" />
        </StyledLink>
      </Avatar>
    </Stack>
  );
}
