import React from "react";
import { Card, CardContent, Grid, Typography, useTheme } from "@mui/material";
import data from "../../data/advantages.json";

export const Advantages = () => {
  const theme = useTheme();

  const styles = {
    card: {
      maxWidth: "100%",
    },
    id: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
      position: "absolute",
      top: "20px",
      left: 0,
      right: 0,
      margin: "0 auto",
      width: "30px",
      height: "30px",
      borderRadius: "50%",
    },
    imgBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyConetnet: "center",
      backgroundColor: theme.palette.primary.main,
      position: "relative",
    },
    img: {
      display: "flex",
      margin: "10px auto",
      maxWidth: 60,
      maxHeight: 60,
      // [theme.breakpoints.down("md")]: { maxWidth: 60, maxHeight: 60 },
    },
    title: {
      fontSize: theme.typography.h6,
      fontWeight: 700,
      minHeight: "65px",
      [theme.breakpoints.down("md")]: {
        minHeight: "40px",
      },
      [theme.breakpoints.down("sm")]: {
        minHeight: 0,
      },
    },
    subTitle: {
      minHeight: "120px",
      color: theme.palette.text.primary,
      [theme.breakpoints.down("md")]: {
        minHeight: "100px",
      },
      [theme.breakpoints.down("sm")]: {
        minHeight: 0,
      },
    },
  };

  const advantages = data.advantages.map((item: any) => (
    <Grid item xs={12} sm={6} md={6} lg={4} key={item.id}>
      <Card sx={styles.card}>
        <Grid container>
          <Grid item xs={3} sx={styles.imgBox}>
            <Typography
              gutterBottom
              variant="h4"
              component="div"
              sx={styles.id}
            >
              {item.id + 1}
            </Typography>
            <img
              style={styles.img}
              src={`/static/icons/${item.img}`}
              alt={item.alt}
            />
          </Grid>
          <Grid item xs={9}>
            <CardContent>
              <Typography
                gutterBottom
                variant="body1"
                component="div"
                sx={styles.title}
              >
                {item.title}
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={styles.subTitle}
              >
                {item.text}
              </Typography>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  ));

  return (
    <Grid container spacing={3}>
      {advantages}
    </Grid>
  );
};
