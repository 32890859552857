import React from "react";
import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import { CallBack } from "@components/callBack";

export const Excursion = () => {
  const theme = useTheme();

  const styles = {
    wrapper: {
      display: "flex",
      width: "100%",
      backgroundImage: "url(static/main-bg/bg-5.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      [theme.breakpoints.down("lg")]: {
        minHeight: "15vh",
      },
    },
    gradient: {
      width: "100%",
      minHeight: "100%",
      background: "linear-gradient(133deg, #fff, transparent)",
      padding: "20px",
      [theme.breakpoints.down("sm")]: {
        padding: "20px 10px",
      },
    },
    title: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "center",
      fontSize: theme.typography.h1,
      lineHeight: 1.5,
      maxWidth: "600px",
      margin: "15px auto",
      [theme.breakpoints.down("md")]: {
        fontSize: theme.typography.h2,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: theme.typography.h3,
      },
    },
    spanTitle: {
      fontSize: theme.typography.h1,
      [theme.breakpoints.down("md")]: {
        fontSize: theme.typography.h2,
      },
      lineHeight: 1.5,
      [theme.breakpoints.down("sm")]: {
        fontSize: theme.typography.h3,
      },
      fontWeight: 900,
    },
    subTitle: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "center",
      fontSize: theme.typography.h4,
      margin: "30px auto",
    },
    subTitleBox: { borderRadius: "5px" },
    itemWrapper: {
      display: "flex",
      gap: "10px",
      marginBottom: "15px",
      borderRadius: "5px",
    },
    check: {
      color: theme.palette.primary.main,
    },
  };

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.gradient}>
        <Container
          maxWidth="lg"
          sx={{
            marginTop: 5,
            marginBottom: 5,
            [theme.breakpoints.down("sm")]: {
              padding: 0,
            },
          }}
        >
          <Typography variant="body1" sx={styles.title}>
            Предлагаем вам <br />
            <Typography variant="body1" component="span" sx={styles.spanTitle}>
              записаться на бесплатную групповую экскурсию
            </Typography>{" "}
            по строящимся объектам в Севастополе
          </Typography>
          <Typography variant="body1" sx={styles.subTitle}>
            На экскурсии вы сможете пообщаться с прорабом и строителями, а
            также:
          </Typography>
          <Grid container sx={styles.subTitleBox}>
            <Grid item lg={4} md={4} sm={12} sx={styles.itemWrapper}>
              <CheckCircle sx={styles.check} />
              <Typography variant="body1">
                Познакомитесь с технологией строительства
              </Typography>
            </Grid>
            <Grid item lg={4} md={4} sm={12} sx={styles.itemWrapper}>
              <CheckCircle sx={styles.check} />
              <Typography variant="body1">
                Оцените качество материалов на стройплощадке
              </Typography>
            </Grid>
            <Grid item lg={4} md={4} sm={12} sx={styles.itemWrapper}>
              <CheckCircle sx={styles.check} />
              <Typography variant="body1">
                Зададите вопросы руководителю строительства
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="body1" sx={styles.subTitle}>
            Укажите когда вам удобно посетить экскурсию и оставьте телефон
          </Typography>
          <CallBack excursion={true} />
        </Container>
      </Box>
    </Box>
  );
}