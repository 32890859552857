import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import CalculateIcon from "@mui/icons-material/Calculate";
import { BreadCrumbs } from "@components/breadcrumbs";
import { popupModalToggleAction } from "@features/popup";
import { Quiz } from "@components/quiz";
import { Faq } from "@features/faq";
import { MainPrice } from "@components/main-price";
import { RandomProjects } from "@features/projects/projects-random";
import projects from "../data/projects.json";
import { Achievements } from "@components/achievements";
import Sber from "@components/assets/sber.png";

export function HomePage() {
  const popup = useSelector((state: ApplicationState) => state.reducers.popup);
  const dispatch = useDispatch();
  const theme = useTheme();

  const styles = {
    wrapper: {
      display: "flex",
      width: "100%",
      minHeight: "100vh",
      backgroundImage: "url(/static/main-bg/bg-1.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "center center",
    },
    gradient: {
      width: "100%",
      minHeight: "100%",
      background: "linear-gradient(133deg, #101010, transparent)",
      pb: "10px",
    },
    topContainer: {
      paddingTop: { xs: "95px", sm: "140px", md: "180px" },
    },
    topTitle: {
      fontSize: { xs: "1.4rem", sm: "1.6rem", md: "1.8rem", lg: "2.2rem" },
      color: theme.palette.primary.contrastText,
    },
    topSubtitleStrong: {
      fontSize: { xs: "1rem", sm: "1.2rem", md: "1.4rem", lg: "1.6rem" },
      fontWeight: { sx: 400, md: 700 },
      color: theme.palette.primary.contrastText,
      marginTop: { xs: "20px", lg: "50px" },
    },
    topSubtitle: {
      fontSize: { xs: "1rem", sm: "1.2rem", md: "1.4rem", lg: "1.6rem" },
      color: theme.palette.primary.contrastText,
      marginTop: { xs: "20px", lg: "50px" },
    },
    topSubtitleBottom: {
      fontSize: theme.typography.h3,
      color: theme.palette.primary.contrastText,
      marginTop: { xs: "20px", lg: "40px" },
    },
    topPreferenses: {
      marginTop: "20px",
    },
    topPreferensesItem: {
      fw: 700,
      color: theme.palette.primary.contrastText,
      maxWidth: 300,
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("lg")]: {
        maxWidth: "100%",
      },
    },
    btnBox: {
      display: { xs: "block", md: "flex" },
      justifyContent: "space-between",
      width: "100%",
      borderRadius: "10px",
      backgroundColor: "rgba(250,250,250,0.15)",
      padding: { xs: "10px", md: "10px 20px" },
      marginTop: "10px",
    },
    topIcons: {
      marginRight: "10px",
    },
    btnText: {
      fontSize: { xs: "12px", sm: "16px", md: "18px" },
      color: theme.palette.primary.contrastText,
      textAlign: { xs: "center", md: "left" },
    },
    btnTextStrong: {
      fontSize: { xs: "14px", sm: "18px", md: "20px" },
      fontWeight: 700,
      color: theme.palette.primary.main,
      textAlign: { xs: "center", md: "left" },
    },
    topButton: {
      margin: { xs: "20px auto", md: 0 },
      minWidth: { xs: 0, md: "300px" },
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        fontSize: 11,
        width: "100%",
      },
    },
    topButtonIcon: {
      mr: "15px",
    },
    devider: {
      m: "15px auto",
    },
    contentContainer: {
      m: "40px auto",
    },
    quizWrapper: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    quizBox: {
      paddingTop: "30px",
    },
    quizBoxGradient: {
      width: "100%",
      height: "100%",
      background:
        "linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%)",
    },
    quizBg: {
      display: "flex",
      justifyContent: "right",
      width: "100%",
      height: "100%",
      backgroundImage: "url(/static/main-bg/bg-3.jpg)",
      // transform: "scale(-1, 1)",
      backgroundSize: "cover",
      backgroundPosition: "center right",
    },
    faqBg: {
      display: "flex",
      width: "100%",
      height: "100%",
      backgroundImage: "url(/static/main-bg/bg-4.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "center center",
    },
  };

  return (
    <>
      <Grid sx={styles.wrapper}>
        <Box sx={styles.gradient}>
          <Container maxWidth="lg" sx={styles.topContainer}>
            <BreadCrumbs />
            <Typography variant="h1" sx={styles.topTitle}>
              Строительство домов под ключ в Севастополе и Крыму
            </Typography>
            <Typography variant="h2" sx={styles.topSubtitleStrong}>
              Современные технологии строительства
            </Typography>
            <Typography variant="h4" sx={styles.topSubtitle}>
              Мы помогаем обрести уют, с применением высококачественных
              материалов, способных прослужить в течении длительного времени
            </Typography>
            <Grid container sx={styles.topPreferenses} spacing={1}>
              <Grid item sm={12} md={6} lg={4} sx={styles.topPreferensesItem}>
                <CheckCircle color="primary" sx={styles.topIcons} />
                <Typography variant="h6">Гарантия 60 месяцев</Typography>
              </Grid>
              <Grid item sm={12} md={6} lg={4} sx={styles.topPreferensesItem}>
                <CheckCircle color="primary" sx={styles.topIcons} />
                <Typography variant="h6">Опыт работы более 10 лет</Typography>
              </Grid>
              <Grid item sm={12} md={6} lg={4} sx={styles.topPreferensesItem}>
                <CheckCircle color="primary" sx={styles.topIcons} />
                <Typography variant="h6">Соблюдение сроков</Typography>
              </Grid>
              <Grid item sm={12} md={6} lg={4} sx={styles.topPreferensesItem}>
                <CheckCircle color="primary" sx={styles.topIcons} />
                <Typography variant="h6">Полный цикл работ</Typography>
              </Grid>
              <Grid item sm={12} md={6} lg={4} sx={styles.topPreferensesItem}>
                <CheckCircle color="primary" sx={styles.topIcons} />
                <Typography variant="h6">
                  Поэтапная оплата и фиксированная стоимость
                </Typography>
              </Grid>
            </Grid>
            <Grid sx={styles.btnBox}>
              <Typography variant="body1" sx={styles.btnTextStrong}>
                Получите бесплатную консультацию{" "}
                <Typography
                  component="br"
                  sx={{ display: { md: "block", lg: "none" } }}
                />{" "}
                <Typography
                  variant="body1"
                  component="strong"
                  sx={styles.btnText}
                >
                  "С чего начать строительство дома" <br /> + бесплатный расчет
                  стоимости проекта + скидку на февраль
                </Typography>
              </Typography>
              <Button
                onClick={() => dispatch(popupModalToggleAction(!popup))}
                color="secondary"
                variant="contained"
                sx={styles.topButton}
              >
                <CalculateIcon fontSize="large" sx={styles.topButtonIcon} />
                Получить консультацию
                <br />+ расчет стоимости
              </Button>
            </Grid>
          </Container>
        </Box>
      </Grid>
      <Container>
        <Box
          component="div"
          sx={{ marginTop: { xs: 3, md: 5 }, marginBottom: { xs: 3, md: 5 } }}
        >
          <Box textAlign="center">
            <Typography variant="h3" gutterBottom>
              Наша компания является аккредитованным застройщиком компании
            </Typography>
            <Box
              component="img"
              src={Sber}
              sx={{ maxHeight: 60, marginBottom: 5 }}
            />
          </Box>
          <iframe
            id="calculator-iframe"
            src="https://ipoteka.domclick.ru/calc-reg/calculator.html"
            frameBorder="no"
            // scrolling="no"
            width="100%"
            height="600"
          />
          <script src="https://ipoteka.domclick.ru/calc-reg/calculator-injector.js" />
        </Box>
      </Container>
      <Container maxWidth="lg" sx={styles.contentContainer}>
        <Grid container spacing={3} sx={styles.quizBox}>
          <Grid item xs={12} md={6}>
            <Quiz />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.quizWrapper}>
            <Grid sx={styles.quizBg}>
              <Grid sx={styles.quizBoxGradient}></Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={styles.devider} />
        <MainPrice />
        <Divider sx={styles.devider} />
        <Achievements />
      </Container>
      <Container maxWidth="lg" sx={styles.contentContainer}>
        <Divider sx={styles.devider} />
        <Typography
          variant="h3"
          gutterBottom
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: "30px auto",
          }}
        >
          Вопросы / ответы
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Faq />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid sx={styles.faqBg}></Grid>
          </Grid>
        </Grid>
        <Divider sx={styles.devider} />
        <Typography
          variant="h3"
          gutterBottom
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: "30px auto",
          }}
        >
          Проекты и цены
        </Typography>
        <Grid container spacing={9}>
          <RandomProjects data={projects} quantity={6} />
        </Grid>
      </Container>
    </>
  );
}
