import React from "react";
import { Box, Grid, Link, Typography } from "@mui/material";
import appInfo from "@data/app-info.json";

type SubscribeItemType = {
  name: string;
  link: string;
  img: string;
};

function SubscribeItem(props: SubscribeItemType) {
  const { name, link, img } = props;

  const styles = {
    link: {
      maxWidth: "150px",
      maxHeight: "30px",
    },
    imgBox: {
      display: "flex",
      justifyContent: "center",
      alignAitems: "center",
    },
    img: {
      maxWidth: "150px",
      maxHeight: "30px",
      padding: "0 8px",
      boxSizing: "border-box",
    },
  };

  return (
    <Link href={link} sx={styles.link}>
      <Box sx={styles.imgBox}>
        <Box
          component="img"
          src={`/static/socials/${img}`}
          alt={name}
          sx={styles.img}
        />
      </Box>
    </Link>
  );
}

export function Subscribe() {
  const styles = {
    wrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      maxWidth: "900px",
      margin: "0 auto"
    },
    text: {
      fontSize: {xs: "0.9rem", sm: "1rem"}
    }
  };
  return (
    <Grid sx={styles.wrapper}>
      <Typography
        variant="body1"
        fontWeight={700}
        color="primary"
        sx={styles.text}
      >
        Мы в соц сетях.<br/>{" "}
        <Typography component="span" color="secondary" sx={{fontSize: "0.8rem", display: {xs: "none", sm: "flex"}}}>
          Подписывайтесь, чтобы узнать больше!
        </Typography>
      </Typography>
      <Grid sx={{ display: "flex" }}>
        {appInfo.contacts.socials.map((item) => (
          <SubscribeItem
            name={item.name}
            link={item.link}
            img={item.img}
            key={item.id}
          />
        ))}
      </Grid>
    </Grid>
  );
}
