export const portfolio = [
  {
    id: 0,
    title: "Дом 240.8м2 в современном стиле (пгт. Виноградное, г.о. Ялта)",
    livingArea: 240.8,
    totalArea: 280,
    price: 55000000,
    term: 50,
    parameters: [
      { id: 0, name: "Устройство монолитного железобетонного фундамента" },
      { id: 1, name: "Устройство монолитного железобетонного каркаcа" },
      {
        id: 2,
        name: "Устройство монолитных железобетонных перекрытий",
      },
      {
        id: 3,
        name: "Устройство монолитных железобетонных лестничных маршей",
      },
      {
        id: 4,
        name: "Устройство кровли",
      },
      {
        id: 5,
        name: "Фасадные работы",
      },
      {
        id: 6,
        name: "Остекление",
      },
      {
        id: 7,
        name: "Организация инженерных коммуникаций (электричество, канализация, водопровод, газ)",
      },
      {
        id: 8,
        name: "Внутренние отделочные работы",
      },
    ],
    period: {
      start: "02.11.23",
      end: "17.03.24",
    },
    folder: "vinogradnoe",
    images: Array.from(Array(34).keys()).map((i: number) => {
      return {
        id: i,
        name: `vinogradnoe (${i + 1}).jpg`,
        folder: "vinogradnoe",
      };
    }),
    feedback: [
      {
        customerName: "Лариса",
        customerAvatar: "avatar1.png",
        feedbackTitle: "Очень довольна результатом",
        feedbackText:
          "Сделали всё просто замечательно — от самого составления проекта до окончания стройки. Сдали дом в срок, не было никаких проблем — быстро, качественно построили. Очень довольна результатом.",
      },
    ],
  },
  {
    id: 1,
    title: "Дом 270м2 в стиле хай-тек (Черноморское)",
    livingArea: 220,
    totalArea: 270,
    price: 5550000,
    term: 50,
    parameters: [
      { id: 0, name: "Устройство монолитного железобетонного фундамента" },
      { id: 1, name: "Устройство монолитного железобетонного каркаcа" },
      {
        id: 2,
        name: "Устройство монолитных железобетонных перекрытий",
      },
      {
        id: 3,
        name: "Устройство монолитных железобетонных лестничных маршей",
      },
      { id: 4, name: "Устройство монолитного железобетонного бассейна" },
      {
        id: 5,
        name: "Устройство кровли",
      },
      {
        id: 6,
        name: "Фасадные работы",
      },
      {
        id: 7,
        name: "Остекление",
      },
      {
        id: 8,
        name: "Организация инженерных коммуникаций (электричество, канализация, водопровод, газ)",
      },
      {
        id: 9,
        name: "Внутренние отделочные работы",
      },
    ],
    period: {
      start: "03.11.22",
      end: "14.04.23",
    },
    folder: "chernomorskoe-3",
    images: Array.from(Array(69).keys()).map((i: number) => {
      return {
        id: i,
        name: `chernomorskoe-3 (${i + 1}).jpg`,
        folder: "chernomorskoe-3",
      };
    }),
    feedback: [
      {
        customerName: "Лариса",
        customerAvatar: "avatar1.png",
        feedbackTitle: "Очень довольна результатом",
        feedbackText:
          "Сделали всё просто замечательно — от самого составления проекта до окончания стройки. Сдали дом в срок, не было никаких проблем — быстро, качественно построили. Очень довольна результатом.",
      },
    ],
  },
  {
    id: 2,
    title: "Монолитный дом (Знаменское - Черноморский район)",
    livingArea: 220,
    totalArea: 270,
    price: 5550000,
    term: 50,
    parameters: [
      {
        id: 0,
        name: "Устройство монолитной железобетонной фундаментной плиты",
      },
      {
        id: 1,
        name: "Устройство монолитных, железобетонных стен и простенков",
      },
      {
        id: 2,
        name: "Устройство монолитных железобетонных перекрытий",
      },
      {
        id: 3,
        name: "Устройство кровли",
      },
      {
        id: 4,
        name: "Фасадные работы",
      },
      {
        id: 5,
        name: "Остекление",
      },
      {
        id: 6,
        name: "Организация инженерных коммуникаций (электричество, канализация, водопровод, газ)",
      },
      {
        id: 7,
        name: "Внутренние отделочные работы",
      },
    ],
    period: {
      start: "01.10.23",
      end: "20.12.23",
    },
    folder: "chernomorskoe-2",
    images: Array.from(Array(21).keys()).map((i: number) => {
      return {
        id: i,
        name: `chernomorskoe-2 (${i + 1}).jpg`,
        folder: "chernomorskoe-2",
      };
    }),
    feedback: [
      {
        customerName: "Лариса",
        customerAvatar: "avatar1.png",
        feedbackTitle: "Очень довольна результатом",
        feedbackText:
          "Сделали всё просто замечательно — от самого составления проекта до окончания стройки. Сдали дом в срок, не было никаких проблем — быстро, качественно построили. Очень довольна результатом.",
      },
    ],
  },
  {
    id: 3,
    title: "Дом в хай-тек стиле (Солдатский пляж - Севастополь)",
    livingArea: 220,
    totalArea: 270,
    price: 5550000,
    term: 50,
    parameters: [
      {
        id: 0,
        name: "Устройство монолитной железобетонной фундаментной плиты",
      },
      {
        id: 1,
        name: "Устройство монолитных, железобетонных стен и простенков",
      },
      {
        id: 2,
        name: "Устройство монолитных железобетонных перекрытий",
      },
      {
        id: 3,
        name: "Устройство кровли",
      },
      {
        id: 4,
        name: "Фасадные работы",
      },
      {
        id: 5,
        name: "Остекление",
      },
      {
        id: 6,
        name: "Организация инженерных коммуникаций (электричество, канализация, водопровод, газ)",
      },
      {
        id: 7,
        name: "Внутренние отделочные работы",
      },
    ],
    period: {
      start: "12.04.23",
      end: "26.06.23",
    },
    folder: "soldatskiy",
    images: Array.from(Array(17).keys()).map((i: number) => {
      return {
        id: i,
        name: `soldatskiy (${i + 1}).jpg`,
        folder: "soldatskiy",
      };
    }),
    feedback: [
      {
        customerName: "Лариса",
        customerAvatar: "avatar1.png",
        feedbackTitle: "Очень довольна результатом",
        feedbackText:
          "Сделали всё просто замечательно — от самого составления проекта до окончания стройки. Сдали дом в срок, не было никаких проблем — быстро, качественно построили. Очень довольна результатом.",
      },
    ],
  },
  {
    id: 4,
    title: "Дом 270м2 в современном стиле (Севастополь - Гераклея)",
    livingArea: 220,
    totalArea: 270,
    price: 5550000,
    term: 50,
    parameters: [
      {
        id: 0,
        name: "Устройство монолитной железобетонной фундаментной плиты",
      },
      {
        id: 1,
        name: "Устройство монолитных, железобетонных стен и простенков",
      },
      {
        id: 2,
        name: "Устройство монолитных железобетонных перекрытий",
      },
      {
        id: 3,
        name: "Устройство кровли",
      },
      {
        id: 4,
        name: "Фасадные работы",
      },
      {
        id: 5,
        name: "Остекление",
      },
      {
        id: 6,
        name: "Организация инженерных коммуникаций (электричество, канализация, водопровод, газ)",
      },
      {
        id: 7,
        name: "Внутренние отделочные работы",
      },
    ],
    period: {
      start: "16.05.23",
      end: "23.10.23",
    },
    folder: "herakleya",
    images: Array.from(Array(40).keys()).map((i: number) => {
      return {
        id: i,
        name: `herakleya (${i + 1}).jpg`,
        folder: "herakleya",
      };
    }),
    feedback: [
      {
        customerName: "Лариса",
        customerAvatar: "avatar1.png",
        feedbackTitle: "Очень довольна результатом",
        feedbackText:
          "Сделали всё просто замечательно — от самого составления проекта до окончания стройки. Сдали дом в срок, не было никаких проблем — быстро, качественно построили. Очень довольна результатом.",
      },
    ],
  },
  {
    id: 5,
    title: "Мини гостинница (Севастополь - Гераклея)",
    livingArea: 220,
    totalArea: 270,
    price: 5550000,
    term: 50,
    parameters: [
      {
        id: 0,
        name: "Устройство монолитной железобетонной фундаментной плиты",
      },
      {
        id: 1,
        name: "Устройство монолитных, железобетонных стен и простенков",
      },
      {
        id: 2,
        name: "Устройство монолитных железобетонных перекрытий",
      },
      {
        id: 3,
        name: "Устройство кровли",
      },
      {
        id: 4,
        name: "Фасадные работы",
      },
      {
        id: 5,
        name: "Остекление",
      },
      {
        id: 6,
        name: "Организация инженерных коммуникаций (электричество, канализация, водопровод, газ)",
      },
      {
        id: 7,
        name: "Внутренние отделочные работы",
      },
    ],
    period: {
      start: "10.09.23",
      end: "27.02.24",
    },
    folder: "herakleya-hotel",
    images: Array.from(Array(18).keys()).map((i: number) => {
      return {
        id: i,
        name: `herakleya-hotel (${i + 1}).jpg`,
        folder: "herakleya-hotel",
      };
    }),
    feedback: [
      {
        customerName: "Лариса",
        customerAvatar: "avatar1.png",
        feedbackTitle: "Очень довольна результатом",
        feedbackText:
          "Сделали всё просто замечательно — от самого составления проекта до окончания стройки. Сдали дом в срок, не было никаких проблем — быстро, качественно построили. Очень довольна результатом.",
      },
    ],
  },
  {
    id: 6,
    title: "Дом в современном стиле (ул.Челнокова - Севастополь)",
    livingArea: 220,
    totalArea: 270,
    price: 5550000,
    term: 50,
    parameters: [
      {
        id: 0,
        name: "Устройство монолитной железобетонной фундаментной плиты",
      },
      {
        id: 1,
        name: "Устройство монолитных, железобетонных стен и простенков",
      },
      {
        id: 2,
        name: "Устройство монолитных железобетонных перекрытий",
      },
      {
        id: 3,
        name: "Устройство кровли",
      },
      {
        id: 4,
        name: "Фасадные работы",
      },
      {
        id: 5,
        name: "Остекление",
      },
      {
        id: 6,
        name: "Организация инженерных коммуникаций (электричество, канализация, водопровод, газ)",
      },
      {
        id: 7,
        name: "Внутренние отделочные работы",
      },
    ],
    period: {
      start: "09.07.23",
      end: "18.09.23",
    },
    folder: "chelnokova",
    images: Array.from(Array(39).keys()).map((i: number) => {
      return {
        id: i,
        name: `chelnokova (${i + 1}).jpg`,
        folder: "chelnokova",
      };
    }),
    feedback: [
      {
        customerName: "Лариса",
        customerAvatar: "avatar1.png",
        feedbackTitle: "Очень довольна результатом",
        feedbackText:
          "Сделали всё просто замечательно — от самого составления проекта до окончания стройки. Сдали дом в срок, не было никаких проблем — быстро, качественно построили. Очень довольна результатом.",
      },
    ],
  },
  {
    id: 7,
    title: "Дом (ул.Муромская - Севастополь)",
    livingArea: 220,
    totalArea: 270,
    price: 5550000,
    term: 50,
    parameters: [
      {
        id: 0,
        name: "Устройство монолитной железобетонной фундаментной плиты",
      },
      {
        id: 1,
        name: "Устройство монолитных, железобетонных стен и простенков",
      },
      {
        id: 2,
        name: "Устройство монолитных железобетонных перекрытий",
      },
      {
        id: 3,
        name: "Устройство кровли",
      },
      {
        id: 4,
        name: "Фасадные работы",
      },
      {
        id: 5,
        name: "Остекление",
      },
      {
        id: 6,
        name: "Организация инженерных коммуникаций (электричество, канализация, водопровод, газ)",
      },
      {
        id: 7,
        name: "Внутренние отделочные работы",
      },
    ],
    period: {
      start: "",
      end: "",
    },
    folder: "yalta",
    images: Array.from(Array(39).keys()).map((i: number) => {
      return {
        id: i,
        name: `yalta (${i + 1}).jpg`,
        folder: "yalta",
      };
    }),
    feedback: [
      {
        customerName: "Лариса",
        customerAvatar: "avatar1.png",
        feedbackTitle: "Очень довольна результатом",
        feedbackText:
          "Сделали всё просто замечательно — от самого составления проекта до окончания стройки. Сдали дом в срок, не было никаких проблем — быстро, качественно построили. Очень довольна результатом.",
      },
    ],
  },
  {
    id: 8,
    title: "Дом 270м2 в стиле хай-тек (Черноморское)",
    livingArea: 220,
    totalArea: 270,
    price: 5550000,
    term: 50,
    parameters: [
      {
        id: 0,
        name: "Устройство монолитной железобетонной фундаментной плиты",
      },
      {
        id: 1,
        name: "Устройство монолитных, железобетонных стен и простенков",
      },
      {
        id: 2,
        name: "Устройство монолитных железобетонных перекрытий",
      },
    ],
    period: {
      start: "",
      end: "",
    },
    folder: "chernomorskoe",
    images: Array.from(Array(46).keys()).map((i: number) => {
      return {
        id: i,
        name: `chernomorskoe (${i + 1}).jpg`,
        folder: "chernomorskoe",
      };
    }),
    feedback: [
      {
        customerName: "Лариса",
        customerAvatar: "avatar1.png",
        feedbackTitle: "Очень довольна результатом",
        feedbackText:
          "Сделали всё просто замечательно — от самого составления проекта до окончания стройки. Сдали дом в срок, не было никаких проблем — быстро, качественно построили. Очень довольна результатом.",
      },
    ],
  },
  {
    id: 9,
    title: "Медицинский кластер - онкология (Севастополь)",
    livingArea: 1000,
    totalArea: 1000,
    price: 5550000,
    term: 50,
    parameters: [
      {
        id: 0,
        name: "Армирование стен, лифтовых и лестничных",
      },
      { id: 1, name: "Укладка бетона" },
      {
        id: 2,
        name: "Устройство монолитных железобетонных колонн",
      },
      {
        id: 3,
        name: "Устройство монолитной железобетонной плиты перекрытия",
      },
    ],
    period: {
      start: "",
      end: "в работе",
    },
    folder: "hospital",
    images: Array.from(Array(25).keys()).map((i: number) => {
      return {
        id: i,
        name: `hospital (${i + 1}).jpg`,
        folder: "hospital",
      };
    }),
    feedback: [
      {
        customerName: "Лариса",
        customerAvatar: "avatar1.png",
        feedbackTitle: "Очень довольна результатом",
        feedbackText:
          "Сделали всё просто замечательно — от самого составления проекта до окончания стройки. Сдали дом в срок, не было никаких проблем — быстро, качественно построили. Очень довольна результатом.",
      },
    ],
  },
  {
    id: 10,
    title:
      "Экотехпарк - мусороперерабатывающий завод, мощностью 200 тыс тонн в год. Государственный заказ: ИГК G22356405122PFK0007520017 (Сакский район)",
    livingArea: 220,
    totalArea: 270,
    price: 5550000,
    term: 50,
    parameters: [
      { id: 0, name: "Устройство монолитных железобетонных роствергов" },
      { id: 1, name: "Подготовка основания" },
      {
        id: 2,
        name: "Устройство подбетонок",
      },
      {
        id: 3,
        name: "Устройство монолитных железобетонных колонн (высота 13м)",
      },
    ],
    period: {
      start: "",
      end: "",
    },
    folder: "garbage",
    images: Array.from(Array(40).keys()).map((i: number) => {
      return {
        id: i,
        name: `garbage (${i + 1}).jpg`,
        folder: "garbage",
      };
    }),
    feedback: [
      {
        customerName: "Лариса",
        customerAvatar: "avatar1.png",
        feedbackTitle: "Очень довольна результатом",
        feedbackText:
          "Сделали всё просто замечательно — от самого составления проекта до окончания стройки. Сдали дом в срок, не было никаких проблем — быстро, качественно построили. Очень довольна результатом.",
      },
    ],
  },
  {
    id: 11,
    title: "Мост (Евпатория)",
    livingArea: 220,
    totalArea: 270,
    price: 5550000,
    term: 50,
    parameters: [
      {
        id: 0,
        name: "Устройство монолитных железобетонных роствергов",
      },
      {
        id: 1,
        name: "Устройство монолитных железобетонных опор моста",
      },
      {
        id: 2,
        name: "Устройство монолитного железобетонного перекрытие",
      },
      {
        id: 3,
        name: "Благоустройство территории",
      },
      { id: 4, name: "Устройство монолитных железобетонных лестничных сходов" },
    ],
    period: {
      start: "",
      end: "",
    },
    folder: "bridge",
    images: Array.from(Array(31).keys()).map((i: number) => {
      return {
        id: i,
        name: `bridge (${i + 1}).jpg`,
        folder: "bridge",
      };
    }),
    feedback: [
      {
        customerName: "Лариса",
        customerAvatar: "avatar1.png",
        feedbackTitle: "Очень довольна результатом",
        feedbackText:
          "Сделали всё просто замечательно — от самого составления проекта до окончания стройки. Сдали дом в срок, не было никаких проблем — быстро, качественно построили. Очень довольна результатом.",
      },
    ],
  },
  {
    id: 12,
    title: "Дом (Севастополь - Вязовая роща)",
    livingArea: 60,
    totalArea: 90,
    price: 4120000,
    parameters: [
      {
        id: 0,
        name: "Устройство монолитного железобетонного ленточного фундамента",
      },
      { id: 1, name: "Черновая плита пола первого этажа" },
      {
        id: 2,
        name: "Устройство колонн и армопоясов первого и второго этажей",
      },
      {
        id: 3,
        name: "Устройство монолитного железобетонного перекрытия",
      },
    ],
    period: {
      start: "",
      end: "",
    },
    folder: "fiolent-90м2",
    images: Array.from(Array(26).keys()).map((i: number) => {
      return {
        id: i,
        name: `viazovaya-rosha (${i + 1}).jpg`,
        folder: "viazovaya-rosha",
      };
    }),
    feedback: [
      {
        customerName: "Антон",
        customerAvatar: "avatar2.png",
        feedbackTitle: "Очень доволен результатом",
        feedbackText:
          "Сделали всё просто замечательно — от самого составления проекта до окончания стройки. Сдали дом в срок, не было никаких проблем — быстро, качественно построили. Очень доволен результатом.",
      },
    ],
  },
  {
    id: 13,
    title: "Дом 60м2 - одноэтажный с плоской кровлей (Севастополь - Фиолент)",
    livingArea: 60,
    totalArea: 90,
    price: 4120000,
    parameters: [
      { id: 0, name: "Ленточный Монолитный железобетонный фундамент" },
      {
        id: 1,
        name: "Монолитные железобетонные колоны и армопояс по перриметру",
      },
      { id: 2, name: "Наполнение стен из газобетона" },
      {
        id: 3,
        name: "Организация инженерных коммуникаций (электричество, канализация, водопровод)",
      },
      { id: 4, name: "Устройство террасы" },
      { id: 5, name: "Благоустройство территории" },
    ],
    period: {
      start: "20.11.23",
      end: "в работе",
    },
    folder: "fiolent-90m2",
    images: Array.from(Array(70).keys()).map((i: number) => {
      return {
        id: i,
        name: `fiolent-90m2 (${i + 1}).jpg`,
        folder: "fiolent-90m2",
      };
    }),
    feedback: [
      {
        customerName: "Антон",
        customerAvatar: "avatar2.png",
        feedbackTitle: "Очень доволен результатом",
        feedbackText:
          "Сделали всё просто замечательно — от самого составления проекта до окончания стройки. Сдали дом в срок, не было никаких проблем — быстро, качественно построили. Очень доволен результатом.",
      },
    ],
  },
  {
    id: 14,
    title: "Дом 250м2 - в стиле хай-тек (Севастополь)",
    livingArea: 210,
    totalArea: 250,
    price: 5220000,
    parameters: [
      {
        id: 1,
        name: "Разработка индивидуального проекта",
      },
      { id: 2, name: "УШП-фундамент" },
      { id: 3, name: "Коробка дома из блоков" },
      {
        id: 4,
        name: "Организация инженерных коммуникаций (электричество, канализация, водопровод, газ)",
      },
      {
        id: 5,
        name: "Установка окон и входной двери",
      },
      { id: 6, name: "Фасадные работы" },
      { id: 7, name: "Внутренняя отделка" },
    ],
    period: {
      start: "",
      end: "",
    },
    folder: "dergachi",
    images: Array.from(Array(13).keys()).map((i: number) => {
      return { id: i, name: `dergachi-${i + 1}.jpg`, folder: "dergachi" };
    }),
    feedback: [
      {
        customerName: "Егор",
        customerAvatar: "avatar2.png",
        feedbackTitle: "Очень доволен результатом",
        feedbackText:
          "Сделали всё просто замечательно — от самого составления проекта до окончания стройки. Сдали дом в срок, не было никаких проблем — быстро, качественно построили. Очень доволен результатом.",
      },
    ],
  },
  {
    id: 15,
    title: "Дом 210м2 - в современном стиле (Новофедоровка)",
    livingArea: 170,
    totalArea: 210,
    price: 4120000,
    parameters: [
      {
        id: 1,
        name: "Разработка индивидуального проекта",
      },
      { id: 2, name: "УШП-фундамент" },
      { id: 3, name: "Коробка дома из блоков" },
      {
        id: 4,
        name: "Организация инженерных коммуникаций (электричество, канализация, водопровод, газ)",
      },
      {
        id: 5,
        name: "Установка окон и входной двери",
      },
    ],
    folder: "novofedorovka",
    period: {
      start: "",
      end: "",
    },
    images: Array.from(Array(13).keys()).map((i: number) => {
      return {
        id: i,
        name: `novofedorovka (${i + 1}).jpg`,
        folder: "novofedorovka",
      };
    }),
    feedback: [
      {
        customerName: "Антон",
        customerAvatar: "avatar2.png",
        feedbackTitle: "Очень доволен результатом",
        feedbackText:
          "Сделали всё просто замечательно — от самого составления проекта до окончания стройки. Сдали дом в срок, не было никаких проблем — быстро, качественно построили. Очень доволен результатом.",
      },
    ],
  },
  {
    id: 16,
    title: "Устройство септика - Севастополь",
    livingArea: 220,
    totalArea: 270,
    price: 5550000,
    term: 50,
    parameters: [
      { id: 2, name: "УШП-фундамент" },
      { id: 3, name: "Коробка дома из блоков" },
      {
        id: 4,
        name: "Организация инженерных коммуникаций (электричество, канализация, водопровод, газ)",
      },
      {
        id: 5,
        name: "Установка окон и входной двери",
      },
      { id: 6, name: "Фасадные работы" },
      { id: 7, name: "Внутренняя отделка" },
    ],
    folder: "septiki",
    period: {
      start: "",
      end: "",
    },
    images: Array.from(Array(12).keys()).map((i: number) => {
      return { id: i, name: `septiki (${i + 1}).jpg`, folder: "septiki" };
    }),
    feedback: [
      {
        customerName: "Лариса",
        customerAvatar: "avatar1.png",
        feedbackTitle: "Очень довольна результатом",
        feedbackText:
          "Сделали всё просто замечательно — от самого составления проекта до окончания стройки. Сдали дом в срок, не было никаких проблем — быстро, качественно построили. Очень довольна результатом.",
      },
    ],
  },
];
