import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "react-image-lightbox/style.css";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";
import {
  lightBoxImgIdAction,
  lightBoxModalToggleAction,
  LightBoxSlider,
} from "@components/lightbox";
import { portfolio } from "@data/portfolio";
import { LazyLoadImageBox } from "@components/lazy-load";
import { grey } from "@mui/material/colors";

export function PortfolioDetail() {
  const clicked = useSelector(
    (state: ApplicationState) => state.reducers.lightbox.clickedId
  );
  const isOpen = useSelector(
    (state: ApplicationState) => state.reducers.lightbox.isOpen
  );
  const dispatch = useDispatch();
  const theme = useTheme();
  const { id } = useParams();

  const styles = {
    title: {
      display: "block",
      fontWeight: 700,
      marginBottom: 2,
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    mobileTitle: {
      display: "none",
      fontWeight: 700,
      marginBottom: 2,
      [theme.breakpoints.down("md")]: {
        display: "block",
      },
    },
    parametersBox: {
      paddingLeft: "30px",
      [theme.breakpoints.down("md")]: {
        paddingLeft: 0,
      },
    },
    priceText: {
      display: "flex",
      alighItems: "end",
      [theme.breakpoints.down("sm")]: {
        fontSize: theme.typography.body1,
      },
      fontWeight: 700,
    },
    price: {
      color: theme.palette.primary.dark,
      fontSize: theme.typography.h4,
      m: "0 8px",
    },
  };

  const curentItem = portfolio.find((item) => item.id === parseInt(id || "0"));

  const imagesList = curentItem ? curentItem.images : [];

  const lightBoxToggle = useCallback(
    (id: number) => {
      dispatch(lightBoxImgIdAction(id));
      dispatch(lightBoxModalToggleAction(!isOpen));
    },
    [dispatch]
  );

  const images = imagesList.map((item: Portfolio.ImageTypes) => (
    <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
      <LazyLoadImageBox
        img={item}
        type={"portfolio"}
        lightBoxToggle={() => lightBoxToggle(item.id)}
      />
    </Grid>
  ));

  const parameters = curentItem?.parameters.map((item) => (
    <Typography key={item.id} sx={{ display: "flex", alignItems: "center" }}>
      <AddCircleOutline
        fontSize="small"
        htmlColor={theme.palette.primary.dark}
        sx={{ marginRight: "5px" }}
      />{" "}
      {item.name}
    </Typography>
  ));

  return (
    <>
      <Grid container>
        <Typography variant="h4" sx={styles.mobileTitle}>
          {curentItem && curentItem.title}
          <Box component="span">{`(${curentItem && curentItem.period.start} - ${
            curentItem && curentItem.period.end
          })`}</Box>
        </Typography>
        <Grid item md={6} sm={12} maxHeight={350}>
          {curentItem && (
            <LazyLoadImageBox
              img={curentItem.images[0]}
              type={"portfolio"}
              lightBoxToggle={() => lightBoxToggle(curentItem.images[0].id)}
            />
          )}
        </Grid>
        <Grid item md={6} sm={12} sx={styles.parametersBox}>
          <Typography variant="h4" sx={styles.title}>
            {curentItem && curentItem.title}
          </Typography>
          {!!(curentItem?.period?.start && curentItem?.period?.end) && (
            <Typography
              variant="body1"
              color="primary"
              fontWeight={600}
              sx={{ my: 1 }}
            >{`(начало работ: ${
              curentItem && curentItem.period.start
            }, окончание работ: ${
              curentItem && curentItem.period.end
            })`}</Typography>
          )}
          {/* <Typography variant="body1" sx={styles.priceText} component="div">
            Стоимость строительства{" "}
            <Typography variant="body1" sx={styles.price}>
              {curentItem && curentItem.price.toLocaleString()}
            </Typography>{" "}
            руб
          </Typography>
          <Typography variant="h6">
            В стоимость вошли работа и материалы:
          </Typography> */}
          {parameters}
        </Grid>
        <Grid container spacing={3} marginTop={"10px"}>
          {images}
        </Grid>
      </Grid>
      <LightBoxSlider images={imagesList} type={"portfolio"} />
    </>
  );
}
