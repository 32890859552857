import React from "react";
import { Routes, Route } from "react-router-dom";
import { Layout } from "@components/layout";
import { HomePage } from "@pages/HomePage";
import { AboutPage } from "@pages/AboutPage";
import { ProjectPage } from "@pages/ProjectPage";
import { ProjectDetailPage } from "@pages/ProjectsDetailPage";
import { ServicesPage } from "@pages/ServicesPage";
import { ServicesDetailPage } from "@pages/ServicesDetailPage";
import { NotFoundPage } from "@pages/NotFoundPage";
import { SellsPage } from "@pages/SellsPage";
import { PortfolioPage } from "@pages/PortfolioPage";
import { PortfolioDetailPage } from "@pages/PortfolioDetailPage";
import { ContactsPage } from "@pages/ContactsPage";
import "./App.css";

export function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="projects" element={<ProjectPage />} />
          <Route path="projects/:id" element={<ProjectDetailPage />} />
          <Route path="services" element={<ServicesPage />} />
          <Route path="services/:id" element={<ServicesDetailPage />} />
          <Route path="sell" element={<SellsPage />} />
          <Route path="portfolio" element={<PortfolioPage />} />
          <Route path="portfolio/:id" element={<PortfolioDetailPage />} />
          <Route path="contacts" element={<ContactsPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </div>
  );
}
