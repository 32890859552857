import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "react-image-lightbox/style.css";
import { Grid, Typography, useTheme } from "@mui/material";
import { Check } from "@mui/icons-material";
import {
  lightBoxImgIdAction,
  lightBoxModalToggleAction,
} from "@components/lightbox";
import projects from "@data/projects.json";
import { Stickers } from "@components/stickers";
import { Carousel } from "@components/carousel";
import { LazyLoadImageBox } from "@components/lazy-load";
import mainPrice from "@data/main-price.json";

export const ProjectDetail = () => {
  const isOpen = useSelector(
    (state: ApplicationState) => state.reducers.lightbox.isOpen
  );
  const dispatch = useDispatch();
  const theme = useTheme();
  const { id } = useParams();

  const styles = {
    infoBox: {
      pl: "30px",
      [theme.breakpoints.down("md")]: {
        pl: 0,
        pt: "20px",
      },
    },
    name: {
      color: theme.palette.primary.dark,
      fontSize: theme.typography.h3,
      textDecoration: "underline",
      display: "block",
      fontWeight: 700,
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    mobileName: {
      color: theme.palette.primary.dark,
      fontSize: theme.typography.h3,
      textDecoration: "underline",
      display: "none",
      fontWeight: 700,
      [theme.breakpoints.down("md")]: {
        display: "block",
      },
      marginBottom: "15px",
    },
    title: {
      p: "10px 0",
      fontSize: theme.typography.h4,
      fontWeight: 700,
      display: "block",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    mobileTitle: {
      p: "10px 0",
      fontSize: theme.typography.h4,
      fontWeight: 700,
      display: "none",
      mb: "15px",
      [theme.breakpoints.down("md")]: {
        display: "block",
      },
    },
    priceText: {
      display: "flex",
      alignItems: "end",
      fontSize: theme.typography.h5,
      fontWeight: 700,
      mb: "15px",
    },
    price: {
      color: theme.palette.primary.dark,
      fontSize: theme.typography.h4,
      fontWeight: 900,
      m: "0 8px",
    },
  };

  const curentItem: any = projects.find(
    (item: Project.ItemTypes) => item.id === parseInt(id || "0")
  );
  const imagesList = curentItem ? curentItem.images : [];

  const lightBoxToggle = useCallback(
    (id: number) => {
      dispatch(lightBoxImgIdAction(id));
      dispatch(lightBoxModalToggleAction(!isOpen));
    },
    [dispatch, isOpen]
  );

  const plans = imagesList
    .filter((item: Project.ImageTypes) => item.category === "plan")
    .map((item: any) => (
      <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
        <LazyLoadImageBox
          img={item}
          type={"projects"}
          isContain={true}
          lightBoxToggle={() => lightBoxToggle(item.id)}
        />
      </Grid>
    ));

  const parameters = curentItem?.parameters.map((item: any) => (
    <Typography key={item.id} sx={{ display: "flex", alignItems: "center" }}>
      <Check
        fontSize="small"
        htmlColor={theme.palette.primary.dark}
        sx={{ marginRight: "5px" }}
      />{" "}
      {item.name}: {item.value}
    </Typography>
  ));

  const price = curentItem.parameters[2].value
    ? Math.floor(
        (curentItem.parameters[2].value as number) * mainPrice[0].price
      ).toLocaleString()
    : "";

  return (
    <>
      <Grid container>
        <Grid>
          <Typography variant="body1" sx={styles.mobileName}>
            {curentItem && curentItem.name}
          </Typography>
          <Typography variant="body1" sx={styles.mobileTitle}>
            {curentItem && curentItem.title}
          </Typography>
        </Grid>
        <Grid item md={6} xs={12} sx={{ position: "relative" }}>
          {curentItem && (
            <Carousel images={imagesList} type={"projects"} isLightBox={true} />
          )}
          <Stickers
            isTop={curentItem ? curentItem.top : false}
            isHit={curentItem ? curentItem?.hit : false}
          />
        </Grid>
        <Grid item md={6} xs={12} sx={styles.infoBox}>
          <Typography variant="body1" sx={styles.name}>
            {curentItem && curentItem.name}
          </Typography>
          <Typography variant="body1" sx={styles.title}>
            {curentItem && curentItem.title}
          </Typography>
          <Typography variant="body1" sx={styles.priceText}>
            Стоимость строительства{" "}
            <Typography variant="body1" sx={styles.price} component="span">
              {price}
            </Typography>{" "}
            руб
          </Typography>
          <Typography variant="h5" sx={{ mb: "10px" }}>
            Параметры проекта:
          </Typography>
          {parameters}
        </Grid>
      </Grid>
      <Grid>
        <Typography variant="body1" sx={styles.title}>
          Планировка
        </Typography>
        <Grid container spacing={3} marginTop={"10px"}>
          {plans}
        </Grid>
        <Typography variant="body1" sx={{ fontWeight: 700, mt: "20px" }}>
          Краткое описание проекта:
        </Typography>
        <Typography variant="body1" sx={{ mt: "20px", mb: "10px" }}>
          {curentItem && curentItem.description}
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 700 }}>
          Преимущества проекта {curentItem && curentItem.name}:
        </Typography>
        <Typography variant="body1" sx={{ mt: "20px", mb: "10px" }}>
          {curentItem && curentItem.advantages}
        </Typography>
      </Grid>
    </>
  );
};
