import { createAction, createReducer } from "@reduxjs/toolkit";

//actions
export const quizModalToggleAction = createAction<boolean>("@@feature/quiz");

//reducers

export const quizModalToggleReducer = createReducer<boolean>(true, (b) => {
  b.addCase(quizModalToggleAction, (state, action) => action.payload);
});

