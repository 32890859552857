import * as React from "react";
import { NavLink } from "react-router-dom";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";

export function PortfolioCard(props: Portfolio.DataTypes) {
  const { data } = props;

  const theme = useTheme();

  const styled = {
    link: { color: theme.palette.text.primary },
    cardTitle: {
      minHeight: 50,
      color: theme.palette.text.primary,
      fontWeight: 700,
      textDecoration: "underline",
    },
    cardSubTitle: {
      color: theme.palette.text.primary,
      fontWeight: 700,
      display: "flex",
      alignItems: "center",
    },
  };

  return (
    <Grid item md={4} sm={6} xs={12}>
      <Card style={{ display: "flex" }}>
        <CardActionArea>
          <NavLink to={`/portfolio/${data.id}`}>
            <CardMedia
              component="img"
              image={
                data.images
                  ? `/static/portfolio/${data.images[0].folder}/${data.images[0].name}`
                  : "/static/portfolio/default-portfolio.jpg"
              }
              alt={data.title}
              height="200"
            />
            <CardContent>
              <Typography variant="h6" textAlign="center" sx={styled.cardTitle}>
                {data.title}
              </Typography>
              {!!(data.period.start && data.period.end) && (
                <Typography
                  variant="body1"
                  color="primary"
                  fontWeight={600}
                  textAlign="center"
                  sx={{ my: 1 }}
                >{`(старт: ${data && data.period.start}, финиш: ${
                  data && data.period.end
                })`}</Typography>
              )}
              {/* <Grid container sx={{ alignItems: "end" }}>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  sx={{
                    display: "flex",
                    alignItem: "center",
                    justifyContent: "right",
                  }}
                >
                  <Typography variant="body1" sx={styled.cardSubTitle}>
                    Стоимость строительства
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  sx={{
                    display: "flex",
                    alignItem: "center",
                    justifyContent: "right",
                  }}
                >
                  <Typography variant="body1" sx={styled.cardSubTitle}>
                    от
                    <span
                      style={{
                        color: theme.palette.primary.dark,
                        fontSize: "1.2rem",
                        marginLeft: "4px",
                        marginRight: "4px",
                      }}
                    >
                      {data.price.toLocaleString()}
                    </span>
                    руб
                  </Typography>
                </Grid>
              </Grid> */}
            </CardContent>
          </NavLink>
          {/* <StyledSeeMoreLink to={`/portfolio/${data.id}`}>
            Посмотреть
          </StyledSeeMoreLink> */}
        </CardActionArea>
      </Card>
    </Grid>
  );
}
