import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Container, Divider, Grid, Typography, useTheme } from "@mui/material";
import { BreadCrumbs } from "@components/breadcrumbs";
import { ProjectCard } from "@features/projects/project-card";
import { ProjectPagination } from "@features/projects/project-pagination";
import { changeSettings, fetchProjectsList } from "@features/projects/models";
import projects from "../data/projects.json";

export function ProjectPage() {
  const settings = useSelector((state: ApplicationState) => {
    return {
      pageSize: state.reducers.projects.settings.pageSize,
      pageCount: state.reducers.projects.settings.pageCount,
      pageIndex: state.reducers.projects.settings.pageIndex,
    };
  });
  const data = useSelector(
    (state: ApplicationState) => state.reducers.projects.projectsList
  );

  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    const isRemain = data.length % settings.pageSize;
        
    dispatch(fetchProjectsList(projects));
    dispatch(
      changeSettings({
        pageCount:
          isRemain === 0
            ? data.length / settings.pageSize
            : Math.floor(data.length / settings.pageSize) + 1,
      })
    );
  }, [dispatch, data.length, settings.pageSize]);

  useMemo(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const lastProjectIndex: number = settings.pageIndex * settings.pageSize;
  const firstProjectIndex: number = lastProjectIndex - settings.pageSize;
  const curentPageProjects = data.slice(firstProjectIndex, lastProjectIndex);

  const styles = {
    wrapper: {
      display: "flex",
      width: "100%",
      minHeight: "40vh",
      [theme.breakpoints.down("lg")]: {
        minHeight: "15vh",
      },
      backgroundImage: "url(/static/main-bg/bg-1.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "center center",
    },
    gradient: {
      width: "100%",
      minHeight: "100%",
      background: "linear-gradient(133deg, #101010, transparent)",
      pb: "10px",
    },
    topContainer: {
      pt: "65px",
      [theme.breakpoints.up("xl")]: {
        pt: "160px",
      },
      [theme.breakpoints.up("lg")]: {
        pt: "150px",
      },
      [theme.breakpoints.up("md")]: {
        pt: "130px",
      },
      [theme.breakpoints.up("sm")]: {
        pt: "120px",
      },
      [theme.breakpoints.up("xs")]: {
        pt: "80px",
      },
    },
    topTitle: {
      mt: "20px",
      color: theme.palette.primary.contrastText,
    },
    topSubtitle: {
      fontWeight: 700,
      color: theme.palette.primary.contrastText,
      mt: "40px",
      [theme.breakpoints.down("lg")]: {
        mt: "20px",
      },
    },
    topSubtitleStrong: {
      fontWidth: 700,
    },
    topSubtitleBottom: {
      fontSize: theme.typography.h3,
      color: theme.palette.primary.contrastText,
      mt: "40px",
      [theme.breakpoints.down("lg")]: {
        mt: "20px",
      },
    },
    topPreferenses: {
      mt: "20px",
      [theme.breakpoints.down("lg")]: {
        mt: "20px",
      },
    },
    topPreferensesItem: {
      fw: 700,
      color: theme.palette.primary.contrastText,
      maxWidth: 300,
      display: "flex",
      alignItems: "center",
      pr: "20px",
      [theme.breakpoints.down("lg")]: {
        maxWidth: "100%",
      },
    },
    topIcons: {
      marginRight: "10px",
    },
    topButton: {
      mt: "40px",
      mb: "40px",
      [theme.breakpoints.down("md")]: {
        display: "flex",
        m: "40px auto",
      },
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        width: "100%",
        fontSize: 12,
      },
    },
    topButtonIcon: {
      mr: "15px",
    },
    contentContainer: {
      mt: "40px",
    },

    devider: {
      mt: "60px",
      mb: "40px",
    },
  };

  return (
    <>
      <Box sx={styles.wrapper}>
        <Box sx={styles.gradient}>
          <Container maxWidth="lg" sx={styles.topContainer}>
            <BreadCrumbs />
            <Typography variant="h2" gutterBottom sx={styles.topSubtitle}>
              Предлагаем 600+ проектов домов на Ваш выбор
            </Typography>
          </Container>
        </Box>
      </Box>
      <Container maxWidth="lg" sx={{ marginTop: 5, marginBottom: 5 }}>
        <Grid container spacing={3}>
          {curentPageProjects.map((item: Project.ItemTypes) => (
            <ProjectCard key={item.id} data={item} />
          ))}
        </Grid>
        <Divider sx={styles.devider} />
        <ProjectPagination />
      </Container>
    </>
  );
}
