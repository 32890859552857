import React from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  lightBoxImgIdAction,
  lightBoxModalToggleAction,
} from "@components/lightbox";

type Props = {
  images: any;
  type: string;
};

export const LightBoxSlider = (props: Props) => {
  const { images, type } = props;

  const clicked = useSelector(
    (state: ApplicationState) => state.reducers.lightbox.clickedId
  );
  const isOpen = useSelector(
    (state: ApplicationState) => state.reducers.lightbox.isOpen
  );
  const dispatch = useDispatch();

  return (
    <div>
      {isOpen && (
        <Lightbox
          mainSrc={`/static/${type}/${images[clicked].folder}/${images[clicked].name}`}
          nextSrc={`/static/${type}/${
            images[(clicked + 1) % images.length].folder
          }/${images[(clicked + 1) % images.length].name}`}
          prevSrc={`/static/${type}/${
            images[(clicked + images.length - 1) % images.length].folder
          }/${images[(clicked + images.length - 1) % images.length].name}`}
          mainSrcThumbnail={`/static/${type}/${images[clicked].folder}/thubnails/${images[clicked].name}`}
          prevSrcThumbnail={`/static/${type}/${
            images[(clicked + 1) % images.length].folder
          }/thubnails/${images[(clicked + 1) % images.length].name}`}
          nextSrcThumbnail={`/static/${type}/${
            images[(clicked + images.length - 1) % images.length].folder
          }/thubnails/${
            images[(clicked + images.length - 1) % images.length].name
          }`}
          onCloseRequest={() => dispatch(lightBoxModalToggleAction(false))}
          onMovePrevRequest={() =>
            dispatch(
              lightBoxImgIdAction((clicked + images.length - 1) % images.length)
            )
          }
          onMoveNextRequest={() =>
            dispatch(lightBoxImgIdAction((clicked + 1) % images.length))
          }
          clickOutsideToClose={true}
        />
      )}
    </div>
  );
};
