import { combineReducers, createAction, createReducer as reducer } from "@reduxjs/toolkit";

// types
type SettingsState = {
  pageSize: number;
  pageCount: number;
  pageIndex: number;
};

//action factory
const actionFactory = (() =>
  function <P = void>(key: string) {
    return createAction<P>(`@@features/projects/${key}`);
  })();

const settingsState: SettingsState = {
  pageSize: 9,
  pageCount: 1,
  pageIndex: 1,
};

const projects: Project.ItemsTypes = [];

export type ChangeSettingsArgs = Partial<SettingsState>;

//actions
export const changeSettings = actionFactory<ChangeSettingsArgs>("settings/change");

export const fetchProjectsList = actionFactory<Project.ItemsTypes>("list");

//reducers
const settingsReducer = reducer(settingsState, (b) => {
  b.addCase(changeSettings, (state, { payload }) => ({
    ...state,
    ...payload,
  }));
});

const fetchProjectsListReducer = reducer(projects, (b) => {
  b.addCase(fetchProjectsList, (_s, { payload }) => 
    payload.map((item) => ({...item})),
  );
});


export const ProjectsReducer = combineReducers({
  projectsList: fetchProjectsListReducer,
  settings: settingsReducer,
});
