import { combineReducers } from 'redux';
import { themeToggleReducer } from '@features/theme';
import { popupModalToggleReducer} from "@features/popup";
import { quizModalToggleReducer } from "@components/quiz";
import { lightBoxReducer } from "@components/lightbox";
import { ProjectsReducer } from "@features/projects/models";
import { PortfolioReducer } from "@features/portfolio/models";
import { MainPriceReducer } from '@components/main-price/models';
import { ServicesReducer } from '@features/services/models';

export const appReducers = combineReducers({
  theme: themeToggleReducer,
  popup: popupModalToggleReducer,
  quiz: quizModalToggleReducer,
  lightbox: lightBoxReducer,
  projects: ProjectsReducer,
  portfolio: PortfolioReducer,
  services: ServicesReducer,
  mainPrice: MainPriceReducer,
});
