import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import { store } from "./store/store";
import { App } from "./App";
import "./index.css";
import { ScrollToTop } from "@components/scrollToTop";

const container = document.getElementById("root")!;
const root = createRoot(container);

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      paper: "#ffffff",
    },
    primary: {
      light: "#FCF6BA",
      main: "#BF953F",
      dark: "#AA771C",
      contrastText: "#fff",
    },
    secondary: {
      light: "#FCF6BA",
      main: "#BF953F",
      dark: "#AA771C",
      contrastText: "#fff",
    },
    action: {},
    text: {
      primary: "#212121",
    },
  },
  breakpoints: {
    values: {
      xs: 320,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    // MuiButton: {
    //   styleOverrides: {
    //     root: {
    //       color: "secondary",
    //       variant: "contained",
    //     },
    //   },
    // },
    // MuiMenuItem: {
    //   styleOverrides: {
    //     root: {
    //       color: "#BF953F",
    //     },
    //   },
    // }
  },
  typography: {
    h1: {
      fontSize: "2.2rem",
      "@media (max-width:1200px)": {
        fontSize: "2rem",
      },
      "@media (max-width:900px)": {
        fontSize: "1.8rem",
      },
    },
    h2: {
      fontSize: "2rem",
      "@media (max-width:1200px)": {
        fontSize: "1,8rem",
      },
      "@media (max-width:900px)": {
        fontSize: "1.6rem",
      },
    },
    h3: {
      fontSize: "1.8rem",
      "@media (max-width:1200px)": {
        fontSize: "1.6rem",
      },
      "@media (max-width:900px)": {
        fontSize: "1.4rem",
      },
    },
    h4: {
      fontSize: "1.6rem",
      "@media (max-width:1200px)": {
        fontSize: "1.4rem",
      },
      "@media (max-width:900px)": {
        fontSize: "1.2rem",
      },
    },
    h5: {
      fontSize: "1.4rem",
      "@media (max-width:1200px)": {
        fontSize: "1.2rem",
      },
      "@media (max-width:900px)": {
        fontSize: "1rem",
      },
    },
    h6: {
      fontSize: "1.2rem",
      "@media (max-width:1200px)": {
        fontSize: "1rem",
      },
      "@media (max-width:900px)": {
        fontSize: "0.9rem",
      },
    },
    body1: {
      fontSize: "1rem",
      "@media (max-width:1200px)": {
        fontSize: "0,9rem",
      },
      "@media (max-width:900px)": {
        fontSize: "0,8rem",
      },
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      paper: "transparent",
    },
    text: {
      primary: "#fff",
    },
  },
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={lightTheme}>
          <ScrollToTop />
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
