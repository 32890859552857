import { combineReducers, createAction, createReducer as reducer } from "@reduxjs/toolkit";
import { MainPriceType } from "./types"

//action factory
const actionFactory = (() =>
  function <P = void>(key: string) {
    return createAction<P>(`@@features/main-price/${key}`);
  })();

const mainPrice: MainPriceType[] = [];

//actions
export const fetchMainPriceAction = actionFactory<MainPriceType[]>("list");

//reducers
const fetchMainPriceReducer = reducer(mainPrice, (b) => {
  b.addCase(fetchMainPriceAction, (_s, { payload }) =>
    payload.map((item) => ({ ...item }))
  );
});


export const MainPriceReducer = combineReducers({
  mainPrice: fetchMainPriceReducer,
});
