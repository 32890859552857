import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMainPriceAction } from "./models";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import mainPrice from "@data/main-price.json";

export const MainPrice = () => {
  const data = useSelector(
    (state: ApplicationState) => state.reducers.mainPrice
  );
  const theme = useTheme();

  const styles = {
    priceWrap: {mt: "60px"},
    topTitle: { pl: "24px"},
    link: { color: theme.palette.text.primary },
    cardTitle: {
      minHeight: 50,
      color: theme.palette.primary.dark,
      fontWeight: 700,
    },
    cardSubTitle: {
      color: theme.palette.text.primary,
      fontWeight: 700,
      display: "flex",
      alignItems: "center",
    },
    priceBox: {
      display: "flex",
      alignItem: "center",
      justifyContent: "right",
    },
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchMainPriceAction(mainPrice));
  }, [dispatch]);

  return (
    <Grid container spacing={3} sx={styles.priceWrap}>
      <Typography variant="h4" sx={styles.topTitle}>
        Построим идеальный дом с учетом всех особенностей Крыма и Севастополя
      </Typography>
      {data.mainPrice.map((item: any) => {
        return (
          <Grid item md={6} xs={12} key={item.id}>
            <Card style={{ display: "flex" }}>
              <CardActionArea>
                <NavLink to={`/projects`}>
                  <CardMedia
                    component="img"
                    image={
                      item.img
                        ? `/static/price/${item.img}`
                        : "/static/price/default-portfolio.jpg"
                    }
                    alt={item.title}
                    height="300"
                  />
                  <CardContent>
                    <Typography variant="h6" sx={styles.cardTitle}>
                      {item.title}
                    </Typography>
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        <Typography variant="body1" sx={styles.cardSubTitle}>
                          Стоимость строительства
                        </Typography>
                      </Grid>
                      <Grid item xs={12} lg={6} sx={styles.priceBox}>
                        <Typography variant="body1" sx={styles.cardSubTitle}>
                          от
                          <span
                            style={{
                              display: "flex",
                              color: theme.palette.primary.dark,
                              fontSize: "1.2rem",
                            }}
                          >
                            {item.price.toLocaleString()}
                          </span>
                          руб/м2
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </NavLink>
              </CardActionArea>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};
