import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import data from "@data/faq.json";
import { Grid } from "@mui/material";
import { grey } from "@mui/material/colors";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : grey[300],
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export function Faq() {
  const [expanded, setExpanded] = React.useState<number | false>(0);

  const styles = {
    question: {
      fontWeight: 700,
    }
  }

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const accordionItem = data?.faq?.map((item) => {
    return (
      <Accordion
        expanded={expanded === item.id}
        onChange={handleChange(item.id)}
        key={item.id}
      >
        <AccordionSummary
          aria-controls={`panel${item.id}-content`}
          id={`panel${item.id}-header`}
        >
          <Typography sx={styles.question}>{item.question}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {item.answear.map((ans: any, i: number) => (
            <Typography key={i}>{ans}</Typography>
          ))}
        </AccordionDetails>
      </Accordion>
    );
  });

  return (
    <Grid>
      {accordionItem}
    </Grid>
  );
}
