import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumbs, styled, Typography, useTheme } from "@mui/material";
import { Home } from "@mui/icons-material";
import appInfo from "@data/app-info.json";
import { portfolio } from "@data/portfolio";
import projects from "@data/projects.json";
import services from "@data/services.json";


const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  "&:hover": {
    textDecoration: "underline",
  },
}));

export function BreadCrumbs() {
  const { pathname } = useLocation();
  const pathnames = pathname.split("/").filter((n) => n);

  const theme = useTheme();

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      maxItems={3}
      itemsAfterCollapse={2}
      sx={{ color: theme.palette.grey[500], marginTop: "10px" }}
    >
      {
        pathnames.length > 0 ? (
          <StyledLink to="/">
            <Home />
          </StyledLink>
        ) : null
        // (
        //   <Typography sx={{ color: theme.palette.grey[500] }}>
        //     <Home />
        //   </Typography>
        // )
      }
      {pathnames.map((name, index) => {
        const isCurrent = index === pathnames.length - 1;
        const title = appInfo.menuItems.find(
          (item) => item.path === `/${name}`
        );
        const portfolioMenuItem = portfolio.find(
          (item) => item.id === parseInt(name)
        );
        const projectsMenuItem = projects.find(
          (item) => item.id === parseInt(name)
        );
        const servicesMenuItem = services.find(
          (item) => item.id === parseInt(name)
        );
        const menuItem =
          pathnames[0] === "portfolio" ? portfolioMenuItem : pathnames[0] === "services" ? servicesMenuItem : projectsMenuItem;

        return isCurrent ? (
          <Typography
            key={name}
            variant="body1"
            sx={{ color: theme.palette.grey[400] }}
          >
            {pathnames.length === 2 ? menuItem?.title : title?.menuTitle}
          </Typography>
        ) : (
          <StyledLink key={name} to={`/${name}`}>
            {title?.menuTitle}
          </StyledLink>
        );
      })}
    </Breadcrumbs>
  );
}
