import { createAction, createReducer } from "@reduxjs/toolkit";

//actions
export const popupModalToggleAction = createAction<boolean>("@@feature/popup");

//reducers
export const popupModalToggleReducer = createReducer<boolean>(false, (b) => {
  b.addCase(popupModalToggleAction, (state, action) => action.payload);
});

