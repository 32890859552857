import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { appReducers } from "./reducers";


const devTools = process.env.NODE_ENV !== "production";

export const store = configureStore({
  devTools,
  reducer: {
    reducers(state, action) {
      return appReducers(state, action);
    },
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
