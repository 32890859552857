import React from "react";
import { ProjectCard } from "../project-card";

export const RandomProjects = (props: any) => {
  const { data, quantity } = props;
  let Ids: number[] = [];
  let randomItems: Project.ItemTypes[] = [];

  while (Ids.length < quantity) {
    var r = Math.floor(Math.random() * (data.length - 0) + 0);
    if (Ids.indexOf(r) === -1) Ids.push(r);
  }
  Ids.map((item) => {
    return randomItems.push(data[item]);
  });

  return (
    <>
      {randomItems.map((item: Project.ItemTypes) => (
        <ProjectCard key={item.id} data={item} />
      ))}
    </>
  );
};