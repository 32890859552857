import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { Button, Grid, TextField, Typography, useTheme } from "@mui/material";
import { FormControl } from "@mui/material";
import { popupModalToggleAction } from "@features/popup";
import { telegramBot } from "@components/telegramBot/telegramBot";
import { quizModalToggleAction } from "@components/quiz";
import { validateForm } from "@components/validate-form";
import "dayjs/locale/ru";
import { DatePicker, LocalizationProvider, ruRU } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./styles.css"

export const CallBack = (props: any) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [date, setDate] = useState(null);
  const { excursion, title } = props;

  const dispatch = useDispatch();
  const theme = useTheme();

  const styles = {
    title: {
      textAlign: "center",
      fontSize: theme.typography.h5,
      fontWeight: 700,
      mb: "10px",
    },
    subTitle: {
      textAlign: "center",
      fontWeight: 700,
      color: theme.palette.text.primary,
    },
    subTitleStrong: {
      display: "block",
      fontSize: theme.typography.h4,
      fontWeight: 700,
    },
    formControl: {
      width: "100%",
    },
    button: {
      display: "flex",
      margin: "20px auto 0",
    },
    inputWrapper: {
      p: "20px",
      mt: title ? 0 : "40px",
      backgroundColor: "#fff",
      borderRadius: "5px",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
  };

  const onSubmit = (values: any) => {
    const newValues = {
      customer: {
        customerName: values.customerName,
        customerPhone: values.customerPhone,
        excursionDate: date,
        question: values.question,
      },
    };
    telegramBot(newValues);
    setIsSuccess(!isSuccess);
    setTimeout(() => {
      dispatch(popupModalToggleAction(false));
      dispatch(quizModalToggleAction(true));
    }, 3500);
  };

  return (
    <>
      {!isSuccess && (
        <>
          {!title ? (
            <>
              <Typography variant="body1" sx={styles.title}>
                Укажите {excursion ? "удобный для Вас день," : ""} Ваше имя и
                телефон
              </Typography>
              <Typography variant="body1" sx={styles.subTitle}>
                и мы перезвоним{" "}
                <Typography
                  variant="body1"
                  component="strong"
                  sx={styles.subTitleStrong}
                >
                  в течении 1 минуты!!!
                </Typography>
              </Typography>
            </>
          ) : (
            <Typography variant="body1" sx={styles.title}>
              Отправьте заявку и получите бесплатную консультацию по вашему
              проекту
            </Typography>
          )}
          <FormControl sx={styles.formControl}>
            <Form
              onSubmit={onSubmit}
              validate={validateForm}
              initialValues={{}}
              render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                  <Grid
                    display={excursion ? "flex" : "block"}
                    gap={3}
                    sx={styles.inputWrapper}
                  >
                    {title && (
                      <Field
                        name="question"
                        render={({ input, meta }) => (
                          <TextField
                            {...input}
                            fullWidth
                            label={"Задайте вопрос"}
                            error={
                              meta.touched && (meta.error || meta.submitError)
                            }
                            helperText={
                              meta.touched && (meta.error || meta.submitError)
                            }
                            sx={{ margin: excursion ? 0 : "15px 0" }}
                          />
                        )}
                      />
                    )}
                    {excursion && (
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="ru"
                        localeText={
                          ruRU.components.MuiLocalizationProvider.defaultProps
                            .localeText
                        }
                      >
                        <DatePicker
                          label="Введите дату"
                          value={date}
                          className="DatePicker"
                          onChange={(newDate) => {
                            setDate(newDate);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          inputFormat="DD / MM / YYYY"
                        />
                      </LocalizationProvider>
                    )}
                    <Field
                      name="customerName"
                      render={({ input, meta }) => (
                        <TextField
                          {...input}
                          fullWidth
                          label={"Введите имя"}
                          error={
                            meta.touched && (meta.error || meta.submitError)
                          }
                          helperText={
                            meta.touched && (meta.error || meta.submitError)
                          }
                          sx={{ margin: excursion ? 0 : "15px 0" }}
                        />
                      )}
                    />
                    <Field
                      name="customerPhone"
                      render={({ input, meta }) => (
                        <>
                          <PhoneInput
                            {...input}
                            country={"ru"}
                            searchStyle={{ backgroundColor: "transparent" }}
                            specialLabel={"Введите номер телефона"}
                            inputProps={{
                              name: "phone",
                              required: true,
                            }}
                            inputStyle={
                              meta.touched && (meta.error || meta.submitError)
                                ? { borderColor: "#d32f2f", width: "100%" }
                                : { width: "100%" }
                            }
                            dropdownStyle={{ position: "fixed" }}
                          />
                          {meta.touched && (
                            <Typography
                              variant="body2"
                              style={{
                                color: "#d32f2f",
                                fontSize: "0.75rem",
                                margin: "3px 14px 0 14px",
                              }}
                            >
                              {meta.error || meta.submitError}
                            </Typography>
                          )}
                        </>
                      )}
                    />
                  </Grid>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    disabled={submitting}
                    sx={styles.button}
                  >
                    Позвоните мне
                  </Button>
                </form>
              )}
            />
          </FormControl>
        </>
      )}

      {isSuccess && (
        <div
          style={{
            fontSize: 24,
            fontWeight: 700,
            color: theme.palette.primary.main,
            marginTop: 15,
          }}
        >
          Информация передана менеджеру.
          <br />В ближайшее время, с Вами свяжутся
        </div>
      )}
    </>
  );
};
