import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Container, Link, Typography, useTheme } from "@mui/material";
import { BreadCrumbs } from "@components/breadcrumbs";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

export function NotFoundPage() {
  const theme = useTheme();
  const navigate = useNavigate();

  const styles = {
    wrapper: {
      display: "flex",
      width: "100%",
      minHeight: "40vh",
      [theme.breakpoints.down("lg")]: {
        minHeight: "15vh",
      },
      backgroundImage: "url(/static/main-bg/bg-1.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "center center",
    },
    gradient: {
      width: "100%",
      minHeight: "100%",
      background: "linear-gradient(133deg, #101010, transparent)",
      pb: "10px",
    },
    topContainer: {
      pt: "65px",
      [theme.breakpoints.up("xl")]: {
        pt: "160px",
      },
      [theme.breakpoints.up("lg")]: {
        pt: "150px",
      },
      [theme.breakpoints.up("md")]: {
        pt: "130px",
      },
      [theme.breakpoints.up("sm")]: {
        pt: "120px",
      },
      [theme.breakpoints.up("xs")]: {
        pt: "80px",
      },
    },
    topTitle: {
      color: theme.palette.primary.contrastText,
    },
    topSubtitle: {
      fontWeight: 700,
      color: theme.palette.primary.contrastText,
      mt: "40px",
      [theme.breakpoints.down("lg")]: {
        mt: "20px",
      },
    },
    topSubtitleStrong: {
      fontWidth: 700,
    },
    topSubtitleBottom: {
      fontSize: theme.typography.h3,
      color: theme.palette.primary.contrastText,
      mt: "40px",
      [theme.breakpoints.down("lg")]: {
        mt: "20px",
      },
    },
    contentContainer: {
      m: "40px auto",
    },
    message: {
      display: "flex",
      alignItems: "center",
    },
  };

  return (
    <>
      <Box sx={styles.wrapper}>
        <Box sx={styles.gradient}>
          <Container maxWidth="lg" sx={styles.topContainer}>
            <BreadCrumbs />
            <Typography
              variant="h2"
              gutterBottom
              sx={styles.topSubtitle}
            >Ошибка 404 </Typography>
          </Container>
        </Box>
      </Box>
      <Container maxWidth="lg" sx={styles.contentContainer}>
        <Typography variant="h5" sx={styles.message}>Ой! Страница не найдена. <SentimentVeryDissatisfiedIcon fontSize="large" /></Typography>
        <Link onClick={() => navigate(-1)} className={"active-nav-link"}>
          Вернуться назад
        </Link>
      </Container>
    </>
  );
}
