import React from "react";
import { Container, Grid, Box, styled, Typography, Link, useTheme } from "@mui/material";
import appInfo from "@data/app-info.json";
import { FooterMenuItems } from "@features/footer/footer-menu-items";
import { Email, Phone, Place } from "@mui/icons-material";
import { Messengers } from "@components/messengers";

const FooterBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[900],
  color: theme.palette.primary.contrastText,
  marginTop: "auto",
}));

const FooterContactsBox = styled(Grid)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  display: "flex",
  flexDirection: "column",
}));

export function Footer() {
  const theme = useTheme();

  return (
    <FooterBox px={{ xs: 3, sm: 5 }} py={{ xs: 3, sm: 5 }}>
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          <Grid
            item
            xs={12}
            md={2}
            sx={{
              [theme.breakpoints.down("lg")]: {
                display: "flex",
                justifyContent: "center",
              },
            }}
          >
            <img
              src="/logo512.png"
              alt="footer logo"
              style={{ maxHeight: "80px" }}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <Typography variant="h5" gutterBottom borderBottom={1}>
              Навигация
            </Typography>
            <Box display={"flex"} flexDirection={"column"}>
              <FooterMenuItems items={appInfo.menuItems} />
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Typography variant="h5" gutterBottom borderBottom={1}>
              Контакты
            </Typography>
            <FooterContactsBox item>
              <Link
                type="tel"
                href={"tel:" + appInfo.contacts.phone}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  marginTop: "10px",
                }}
              >
                <Phone sx={{ marginRight: "10px" }} />
                {appInfo.contacts.phone}
              </Link>
              <Link
                type="mailto"
                href={"mailto:" + appInfo.contacts.messengers[3].email}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  marginTop: "10px",
                }}
              >
                <Email sx={{ marginRight: "10px" }} />
                {appInfo.contacts.messengers[3].email}
              </Link>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <Place sx={{ marginRight: "10px" }} />
                {appInfo.contacts.adress}
              </Typography>
              <Box marginTop={"15px"}>
                <Messengers />
              </Box>
            </FooterContactsBox>
          </Grid>
        </Grid>
        <Box
          textAlign="center"
          pt={{ xs: 5, sm: 10 }}
          pb={{ xs: 5, sm: 0 }}
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: 14,
            },
            [theme.breakpoints.down("sm")]: {
              fontSize: 12,
            },
          }}
        >
          &reg; 2014-{new Date().getFullYear()} - Компания "
          {appInfo.companyName}"
        </Box>
      </Container>
    </FooterBox>
  );
}
