import React from "react";
import { Box, Container, Divider, Grid, Typography, useTheme } from "@mui/material";
import { BreadCrumbs } from "@components/breadcrumbs";
import { Advantages } from "@components/advantages";
import { ContactsInfo } from "@features/contacts-info";
import { Quiz } from "@components/quiz";
import { Excursion } from "@components/excursion";

export function SellsPage() {
  const theme = useTheme();

  const styles = {
    wrapper: {
      display: "flex",
      width: "100%",
      minHeight: "40vh",
      [theme.breakpoints.down("lg")]: {
        minHeight: "15vh",
      },
      backgroundImage: "url(/static/main-bg/bg-1.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "center center",
    },
    gradient: {
      width: "100%",
      minHeight: "100%",
      background: "linear-gradient(133deg, #101010, transparent)",
      pb: "10px",
    },
    topContainer: {
      pt: "65px",
      [theme.breakpoints.up("xl")]: {
        pt: "160px",
      },
      [theme.breakpoints.up("lg")]: {
        pt: "150px",
      },
      [theme.breakpoints.up("md")]: {
        pt: "130px",
      },
      [theme.breakpoints.up("sm")]: {
        pt: "120px",
      },
      [theme.breakpoints.up("xs")]: {
        pt: "80px",
      },
    },
    topTitle: {
      color: theme.palette.primary.contrastText,
    },
    topSubtitle: {
      fontWeight: 700,
      color: theme.palette.primary.contrastText,
      mt: "40px",
      [theme.breakpoints.down("lg")]: {
        mt: "20px",
      },
    },
    topSubtitleStrong: {
      fontWidth: 700,
    },
    topSubtitleBottom: {
      fontSize: theme.typography.h3,
      color: theme.palette.primary.contrastText,
      mt: "40px",
      [theme.breakpoints.down("lg")]: {
        mt: "20px",
      },
    },
    topPreferenses: {
      mt: "20px",
      [theme.breakpoints.down("lg")]: {
        mt: "20px",
      },
    },
    topPreferensesItem: {
      fw: 700,
      color: theme.palette.primary.contrastText,
      maxWidth: 300,
      display: "flex",
      alignItems: "center",
      pr: "20px",
      [theme.breakpoints.down("lg")]: {
        maxWidth: "100%",
      },
    },
    topIcons: {
      marginRight: "10px",
    },
    topButton: {
      mt: "40px",
      mb: "40px",
      [theme.breakpoints.down("md")]: {
        display: "flex",
        m: "40px auto",
      },
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        width: "100%",
        fontSize: 12,
      },
    },
    topButtonIcon: {
      mr: "15px",
    },
    contentContainer: {
      m: "40px auto",
    },
    contentGradient: {
      width: "100%",
      minHeight: "100%",
      background: "linear-gradient(133deg, #fff, transparent)",
      pb: "10px",
    },
    bgContainer: {
      backgroundImage: "url(/static/main-bg/bg-2.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "center center",
    },
    contentTitle: {
      color: theme.palette.primary.main,
      paddingTop: "30px",
    },
    contentInner: {
      mt: "30px",
    },
    devider: {
      m: "15px auto",
    },
    quizWrapper: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    quizBox: {
      paddingTop: "30px",
    },
    quizBoxGradient: {
      width: "100%",
      height: "100%",
      background:
        "linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%)",
    },
    quizBg: {
      display: "flex",
      justifyContent: "right",
      width: "100%",
      height: "100%",
      backgroundImage: "url(/static/main-bg/bg-3.jpg)",
      // transform: "scale(-1, 1)",
      backgroundSize: "cover",
      backgroundPosition: "center right",
    },
  };

  return (
    <>
      <Box sx={styles.wrapper}>
        <Box sx={styles.gradient}>
          <Container maxWidth="lg" sx={styles.topContainer}>
            <BreadCrumbs />
            <Typography variant="h2" gutterBottom sx={styles.topSubtitle}>
              Акции
            </Typography>
          </Container>
        </Box>
      </Box>
      <Container maxWidth="lg" sx={styles.contentContainer}>
        <Grid container spacing={3} sx={styles.quizBox}>
          <Grid item xs={12} md={6}>
            <Quiz />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.quizWrapper}>
            <Grid sx={styles.quizBg}>
              <Grid sx={styles.quizBoxGradient}></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Excursion />
      <Container maxWidth="lg" sx={styles.contentContainer}>
        {/* <SwipeableTextMobileStepper /> */}
        <Divider sx={styles.devider} />
      </Container>
      <Grid sx={styles.bgContainer}>
        <Grid sx={styles.contentGradient}>
          <Container maxWidth="lg" sx={styles.contentContainer}>
            <Typography variant="h2" gutterBottom sx={styles.contentTitle}>
              Наши преимущества
            </Typography>
            <Box sx={styles.contentInner}>
              <Advantages />
            </Box>
          </Container>
        </Grid>
      </Grid>
      <Container>
        <Divider sx={styles.devider} />
        <Box sx={styles.contentInner}>
          <ContactsInfo />
        </Box>
      </Container>
    </>
  );
}
