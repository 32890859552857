import React from "react";
import { NavLink } from "react-router-dom";
import "./FooterMenuItems.css";

export function FooterMenuItems(props: any) {
  const { items } = props;
  const menuItems = items.map((item: any) => (
    <NavLink
      key={item.menuTitle}
      to={item.path}
      className={({ isActive }) =>
        isActive ? "footer-nav-link footer-nav-link-active" : "footer-nav-link"
      }
    >
      {item.menuTitle}
    </NavLink>
  ));
  return menuItems;
}
