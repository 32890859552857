import dayjs from "dayjs";
import appInfo from "../../data/app-info.json";

export function telegramBot(props: any) {
  const url = `https://api.telegram.org/bot${appInfo.telegramToken}/sendMessage`;
  const { customer, quizData } = props;

  const answers = quizData
    ? quizData?.map((data: any, index: number) => {
        const stringifyAnswers = `${data.answer}\n`;
        return stringifyAnswers;
      })
    : "";

  const message = `${
    quizData
      ? "Заявка на расчет:"
      : customer.excursionDate
      ? `Заявка на групповую экскурсию на ${dayjs(customer.excursinDate).format(
          "YYYY-MM-DD"
        )}`
      : customer.question
      ? "Вопрос: "
      : "Заказ обратного звонка:"
  }\n${customer.question}\n${
    customer.contact ? customer.contact : "Телефон"
  } +${customer.customerPhone} ${customer.customerName}\n${
    quizData ? "нужно построить:" : ""
  }\n${answers}`;

  const settings = {
    async: true,
    crossDomain: true,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify({
      chat_id: appInfo.telegramChatId,
      text: message,
    }),
  };
  fetch(url, settings);
}
