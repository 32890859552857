import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  useTheme,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { popupModalToggleAction } from "./models";
import { Quiz } from "@components/quiz/Quiz";
import { CallBack } from "@components/callBack";
import { quizModalToggleAction } from "@components/quiz";

export const Popup = () => {
  const open = useSelector((state: ApplicationState) => state.reducers.popup);
  const isQuiz = useSelector((state: ApplicationState) => state.reducers.quiz);
  const dispatch = useDispatch();
  const theme = useTheme();
  

  const styles = {
    dialog: {
      padding: 0,
      margin: 0,
    },
    dialogActions: {
      position: "absolute",
      right: 0,
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },
    dialogContent: {
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },
    contentBox: {
      padding: "30px 10px 15px"
    },
  };

  

  const isOpen = () => {
    dispatch(popupModalToggleAction(!open));
    if (!isQuiz) {
      dispatch(quizModalToggleAction(true));
    }
  };

  return (
    <Dialog
      onClose={isOpen}
      open={open}
      sx={styles.dialog}
      PaperProps={{ sx: { margin: "10px" } }}
    >
      <DialogContent sx={styles.dialogContent}>
        <DialogActions sx={styles.dialogActions}>
          <IconButton autoFocus onClick={isOpen}>
            <Close />
          </IconButton>
        </DialogActions>
        <Box sx={styles.contentBox}>
          {isQuiz ? (
            <Quiz />
          ) : (
            <CallBack />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
