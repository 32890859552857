import * as React from "react";
import { NavLink } from "react-router-dom";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { Stickers } from "@components/stickers";
import { AspectRatio, Height, ViewInAr, Widgets } from "@mui/icons-material";
import mainPrice from "@data/main-price.json";

export function ProjectCard(props: Project.DataTypes) {
  const { data } = props;
  const theme = useTheme();

  const styles = {
    link: { color: theme.palette.text.primary },
    cardTitle: {
      color: theme.palette.primary.main,
      fontWeight: 700,
    },
    cardSubTitle: {
      display: "flex",
      alignItems: "center",
      color: theme.palette.text.primary,
      fontWeight: 700,
    },
    parameter: {
      color: theme.palette.text.primary,
      fontSize: theme.typography.body2,
      display: "flex",
      alignItems: "center",
      marginTop: "8px",
    },
    icons: {
      color: theme.palette.primary.dark,
      marginRight: "6px",
    },
  };

  const price = data.parameters[2].value
    ? Math.floor(
        (data.parameters[2].value as number) * mainPrice[0].price
      ).toLocaleString()
    : "";

  return (
    <Grid item md={4} sm={6} xs={12}>
      <Card style={{ display: "flex" }}>
        <CardActionArea>
          <NavLink to={`/projects/${data.id}`}>
            <Grid>
              <CardMedia
                component="img"
                image={
                  data.images
                    ? `/static/projects/${data.images[0].folder}/${data.images[0].name}`
                    : "/static/default-portfolio.jpg"
                }
                alt={`Project ${data.images[0].folder} photo`}
                height="200"
              />
              <Stickers isTop={data.top} isHit={data.hit} />
            </Grid>
            <CardContent>
              <Typography variant="h6" sx={styles.cardTitle}>
                {data.name}
              </Typography>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  [theme.breakpoints.down("sm")]: {
                    flexWrap: "no-wrap",
                  },
                }}
              >
                <Typography sx={styles.parameter}>
                  <AspectRatio sx={styles.icons} /> Размеры:{" "}
                  <span style={{ fontWeight: 700 }}>
                    {data.parameters[3].value}x{data.parameters[4].value}
                  </span>
                </Typography>
                <Typography sx={styles.parameter}>
                  <ViewInAr sx={styles.icons} /> Площадь:{" "}
                  <span style={{ fontWeight: 700 }}>
                    {data.parameters[1].value}м2
                  </span>
                </Typography>
                <Typography sx={styles.parameter}>
                  <Height sx={styles.icons} /> Этажей:{" "}
                  <span style={{ fontWeight: 700 }}>
                    {data.parameters[0].value}
                  </span>
                </Typography>
                <Typography sx={styles.parameter}>
                  <Widgets sx={styles.icons} /> Комнат:{" "}
                  <span style={{ fontWeight: 700 }}>
                    {data.parameters[5].value}
                  </span>
                </Typography>
              </Grid>
              <Grid container sx={{ marginTop: "15px" }}>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  sx={{
                    display: "flex",
                    alignItem: "center",
                    justifyContent: "right",
                  }}
                >
                  <Typography variant="body1" sx={styles.cardSubTitle}>
                    Стоимость строительства
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "right",
                  }}
                >
                  <Typography variant="body1" sx={styles.cardSubTitle}>
                    от
                    <span
                      style={{
                        color: theme.palette.primary.dark,
                        fontSize: "1.2rem",
                        marginLeft: "4px",
                        marginRight: "4px",
                      }}
                    >
                      {price}
                    </span>
                    руб
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </NavLink>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
