import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

type Props = {
  img: any;
  type: string;
  height?: string | undefined;
  isContain?: boolean | undefined;
  lightBoxToggle?: () => void;
};

export const LazyLoadImageBox = (props: Props) => {
  const { img, type, height, isContain, lightBoxToggle } = props;

  return (
    <LazyLoadImage
      src={`/static/${type}/${img.folder}/${img.name}`}
      style={{ objectFit: isContain ? "contain" : "cover" }}
      height={height ? height : "100%"}
      width="100%"
      effect="blur"
      placeholderSrc={`/static/${type}/${img.folder}/thubnails/${img.name}`}
      alt={img.alt}
      onClick={lightBoxToggle}
    />
  );
};
