import React from "react";
import { NavLink } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {
  Box,
  Button,
  Grid,
  Link,
  Slide,
  styled,
  Typography,
  useScrollTrigger,
  useTheme,
} from "@mui/material";
import { HeaderMenu } from "./header-menu/HeaderMenu";
import { useDispatch, useSelector } from "react-redux";
import { quizModalToggleAction } from "@components/quiz";
import { popupModalToggleAction } from "@features/popup";
// import { themeToggleAction } from "@features/theme";
import { Messengers } from "@components/messengers";
import appInfo from "@data/app-info.json";
import "./Header.css";

const StyledAppbar = styled(AppBar)(({ theme }) => ({
  flexDirection: "row",
  backgroundColor: theme.palette.grey[900],
  "@media all": {},
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "@media all": {
    maxWidth: 1152,
    width: "100%",
    margin: "0 auto",
    minHeight: 0,
  },
}));

function HideOnScroll(props: any) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export function Header() {
  const open = useSelector((state: ApplicationState) => state.reducers.popup);
  const dispatch = useDispatch();
  const theme = useTheme();

  const styles = {
    appBar: {
      position: "fixed",
      color: "transparent",
      flexDirection: "row",
      backgroundColor: theme.palette.grey[900],
      "@media all": {},
      background: "transparent",
      boxShadow: "none",
      backgroundImage: "linear-gradient(to bottom, #101010 , transparent)",
      zIndex: 999,
    },
    toolBar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "@media all": {
        maxWidth: 1200,
        width: "100%",
        margin: "0 auto",
        minHeight: 0,
      },
    },
    logoBox: {
      objectFit: "contain",
      display: "flex",
      position: "relative",
      alignItems: "center",
      width: "100%",
      height: "100%",
      maxWidth: "80px",
      [theme.breakpoints.up("xl")]: {
        maxWidth: "160px",
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: "140px",
      },
      [theme.breakpoints.up("sm")]: {
        maxWidth: "120px",
      },
      [theme.breakpoints.up("xs")]: {
        maxWidth: "100px",
      },
      marginTop: "5px",
    },
    btnBox: {
      display: "flex",
      width: "100%",
      height: "100%",
      maxWidth: "80px",
      [theme.breakpoints.up("xl")]: {
        maxWidth: "160px",
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: "140px",
      },
      [theme.breakpoints.up("sm")]: {
        maxWidth: "120px",
      },
      [theme.breakpoints.up("xs")]: {
        maxWidth: "100px",
      },
    },
    logoLink: {},
    logo: {
      width: { xs: "85%", sm: "100%" },
    },
    workTime: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "12px",
      color: theme.palette.primary.contrastText,
      [theme.breakpoints.down("sm")]: {
        fontSize: "8px",
      },
    },
    greenDot: {
      display: "flex",
      width: "8px",
      height: "8px",
      borderRadius: "50%",
      backgroundColor: "#25d266",
      marginRight: "8px",
      animation: "glowing 1s infinite",
    },
  };

  const isQuizToggle = () => {
    dispatch(quizModalToggleAction(false));
    dispatch(popupModalToggleAction(!open));
  };

  return (
    <HideOnScroll>
      <StyledAppbar sx={styles.appBar}>
        <StyledToolbar>
          <Grid sx={styles.toolBar}>
            <Grid item xs={3} sm={2} sx={styles.logoBox}>
              <NavLink to="/" style={styles.logoLink}>
                <Box
                  component="img"
                  src="/logo512.png"
                  alt="logo"
                  sx={styles.logo}
                />
              </NavLink>
            </Grid>
            <Grid
              sx={{
                display: "none",
                [theme.breakpoints.down("lg")]: {
                  display: "block",
                },
              }}
            >
              <Link
                type="tel"
                href={`tel:${appInfo.contacts.phone}`}
                sx={{
                  display: "block",
                  textAlign: "center",
                  color: theme.palette.primary.contrastText,
                  textDecoration: "none",
                  padding: "6px",
                  fontSize: 13,
                }}
              >
                <Typography variant="body1" sx={styles.workTime}>
                  <Typography
                    variant="body1"
                    component="span"
                    sx={styles.greenDot}
                  ></Typography>
                  {appInfo.contacts.workingTime}
                </Typography>
                {appInfo.contacts.phone}
              </Link>
              <Button
                onClick={isQuizToggle}
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  width: "100%",
                  [theme.breakpoints.down("lg")]: {
                    justifyContent: "center",
                  },
                  fontSize: 11,
                }}
              >
                Заказать звонок
              </Button>
            </Grid>
            <Grid alignItems={"center"}>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Grid item alignItems={"center"}>
                  {/* <ThemeToggle /> */}
                </Grid>
                <Grid
                  item
                  alignItems={"center"}
                  sx={{
                    [theme.breakpoints.down("lg")]: {
                      display: "none",
                    },
                  }}
                >
                  <Typography variant="body1" sx={styles.workTime}>
                    <Typography
                      variant="body1"
                      component="span"
                      sx={styles.greenDot}
                    ></Typography>
                    Пишите, мы всегда онлайн
                  </Typography>
                  <Messengers />
                </Grid>
                <Grid
                  item
                  alignItems={"center"}
                  sx={{
                    [theme.breakpoints.down("lg")]: {
                      display: "none",
                    },
                  }}
                >
                  <Link
                    type="tel"
                    href={`tel:${appInfo.contacts.phone}`}
                    sx={{
                      display: "block",
                      textAlign: "center",
                      color: theme.palette.primary.contrastText,
                      textDecoration: "none",
                      padding: "0 8px",
                      fontSize: 18,
                    }}
                  >
                    <Typography variant="body1" sx={styles.workTime}>
                      <Typography
                        variant="body1"
                        component="span"
                        sx={styles.greenDot}
                      ></Typography>
                      {appInfo.contacts.workingTime}
                    </Typography>
                    {appInfo.contacts.phone}
                  </Link>
                  <Button
                    onClick={isQuizToggle}
                    sx={{
                      display: "flex",
                      justifyContent: "right",
                      width: "100%",
                      [theme.breakpoints.up("lg")]: {
                        justifyContent: "center",
                      },
                      fontSize: 12,
                    }}
                  >
                    Заказать звонок
                  </Button>
                </Grid>
              </Grid>
              <HeaderMenu />
            </Grid>
          </Grid>
        </StyledToolbar>
      </StyledAppbar>
    </HideOnScroll>
  );
}
