import * as React from "react";
import { NavLink } from "react-router-dom";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";

export function ServicesCard(props: Services.DataTypes) {
  const { data } = props;
  const theme = useTheme();

  const styles = {
    link: { color: theme.palette.text.primary },
    cardTitle: {
      fontSize: theme.typography.body1,
      color: theme.palette.primary.main,
      fontWeight: 700
    },
    cardSubTitle: {
      color: theme.palette.text.primary,
      fontWeight: 700,
      display: "flex",
      alignItems: "cener",
    },
    parameter: {
      color: theme.palette.text.primary,
      fontSize: theme.typography.body2,
      display: "flex",
      marginTop: "8px",
    },
    icons: {
      color: theme.palette.primary.dark,
      marginRight: "6px",
    },
  };

  return (
    <Grid item md={4} sm={6} xs={12}>
      <Card style={{ display: "flex" }}>
        <CardActionArea>
          <NavLink to={`/services/${data.id}`}>
            <Grid>
              <CardMedia
                component="img"
                image={
                  data.images
                    ? `/static/services/${data.images[0].folder}/${data.images[0].name}`
                    : "/static/default-portfolio.jpg"
                }
                alt={`services-${data.images[0].folder}`}
                height="200"
              />
            </Grid>
            <CardContent>
              <Typography variant="body1" sx={styles.cardTitle}>
                {data.title}
              </Typography>
            </CardContent>
          </NavLink>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
