import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import CalculateIcon from "@mui/icons-material/Calculate";
import { BreadCrumbs } from "@components/breadcrumbs";
import { popupModalToggleAction } from "@features/popup";
import appInfo from "@data/app-info.json";
import { Advantages } from "@components/advantages";
import { ContactsInfo } from "@features/contacts-info";
import { Carousel } from "@components/carousel";
import { Achievements } from "@components/achievements";

export function AboutPage() {
  const popup = useSelector((state: ApplicationState) => state.reducers.popup);
  const dispatch = useDispatch();
  const theme = useTheme();

  const styles = {
    wrapper: {
      display: "flex",
      width: "100%",
      minHeight: "40vh",
      [theme.breakpoints.down("lg")]: {
        minHeight: "15vh",
      },
      backgroundImage: "url(/static/main-bg/bg-1.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "center center",
    },
    gradient: {
      width: "100%",
      minHeight: "100%",
      background: "linear-gradient(133deg, #101010, transparent)",
      pb: "10px",
    },
    topContainer: {
      pt: "65px",
      [theme.breakpoints.up("xl")]: {
        pt: "160px",
      },
      [theme.breakpoints.up("lg")]: {
        pt: "150px",
      },
      [theme.breakpoints.up("md")]: {
        pt: "130px",
      },
      [theme.breakpoints.up("sm")]: {
        pt: "120px",
      },
      [theme.breakpoints.up("xs")]: {
        pt: "80px",
      },
    },
    topTitle: {
      color: theme.palette.primary.contrastText,
    },
    topSubtitle: {
      fontWeight: 700,
      color: theme.palette.primary.contrastText,
      mt: "40px",
      [theme.breakpoints.down("lg")]: {
        mt: "20px",
      },
    },
    topSubtitleStrong: {
      fontWidth: 700,
    },
    topSubtitleBottom: {
      fontSize: theme.typography.h3,
      color: theme.palette.primary.contrastText,
      mt: "40px",
      [theme.breakpoints.down("lg")]: {
        mt: "20px",
      },
    },
    topPreferenses: {
      mt: "20px",
      [theme.breakpoints.down("lg")]: {
        mt: "20px",
      },
    },
    topPreferensesItem: {
      fw: 700,
      color: theme.palette.primary.contrastText,
      maxWidth: 300,
      display: "flex",
      alignItems: "center",
      pr: "20px",
      [theme.breakpoints.down("lg")]: {
        maxWidth: "100%",
      },
    },
    topIcons: {
      marginRight: "10px",
    },
    topButton: {
      mt: "40px",
      mb: "40px",
      [theme.breakpoints.down("md")]: {
        display: "flex",
        m: "40px auto",
      },
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        width: "100%",
        fontSize: 12,
      },
    },
    topButtonIcon: {
      mr: "15px",
    },
    contentContainer: {
      m: "40px auto",
    },
    contentInner: {
      margin: "30px 0",
    },
    devider: {
      mt: "40px",
      mb: "40px",
    },
    bgContainer: {
      backgroundImage: "url(/static/main-bg/bg-2.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "center center",
    },
    contentGradient: {
      width: "100%",
      minHeight: "100%",
      background: "linear-gradient(133deg, #fff, transparent)",
      pb: "10px",
    },
    contentTitle: {
      color: theme.palette.text.primary,
      paddingTop: "30px",
    },
  };

  return (
    <>
      <Box sx={styles.wrapper}>
        <Box sx={styles.gradient}>
          <Container maxWidth="lg" sx={styles.topContainer}>
            <BreadCrumbs />
            <Typography variant="h2" gutterBottom sx={styles.topSubtitle}>
              О компании {appInfo.companyName}
            </Typography>
          </Container>
        </Box>
      </Box>
      <Container maxWidth="lg" sx={styles.contentContainer}>
        <Grid container spacing={5}>
          <Grid item xs={12} lg={6}>
            <Typography variant="h4" gutterBottom>
              <strong>
                Компания "{appInfo.companyName}" предоставляет услуги по
                строительству домов
              </strong>{" "}
              под ключ в Севастополе и Крыму.
            </Typography>
            <Divider sx={styles.devider} />
            <Typography variant="body1" gutterBottom>
              Компания "{appInfo.companyName}" оказывает весь спектр
              строительных услуг в одном месте. Наша компания предлагает Вам
              строительство загородных домов для постоянного проживания.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Мы занимаемся строительством домов в Крыму и Севастополе уже более
              10 лет, а также принимаем активное участие в мостостроении
              полуострова,проходя неоднократные проверки со стороны технадзора.
              Нам доверяют объекты государственного значения.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Мы предлагаем своим клиентам:
            </Typography>
            <Typography variant="body1" gutterBottom>
              - проектно-сметную документацию
            </Typography>
            <Typography variant="body1" gutterBottom>
              - возможность поэтапного или частичного строительства
            </Typography>
            <Typography variant="body1" gutterBottom>
              - строительство под ключ - поэтапную оплату по заключённым
              договорам
            </Typography>
            <Typography variant="body1" gutterBottom>
              - неизменную цену после заключения договора
            </Typography>
            <Typography variant="body1" gutterBottom>
              - внимательный подход к вашим пожеланиям
            </Typography>
            <Typography variant="body1" gutterBottom>
              - оптимальную цену
            </Typography>
            <Typography variant="body1" gutterBottom>
              - высокое качество
            </Typography>
            <Typography variant="body1" gutterBottom>
              - гарантию на все виды работ
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Carousel
              images={appInfo.aboutUsImages}
              type={"about"}
              isLightBox={false}
            />
            <Button
              onClick={() => dispatch(popupModalToggleAction(!popup))}
              color="secondary"
              variant="contained"
              sx={styles.topButton}
            >
              <CalculateIcon fontSize="large" />
              Рассчитать стоимость
              <br />
              строительства дома
            </Button>
          </Grid>
        </Grid>
        <Divider sx={styles.devider} />
        <Achievements />
      </Container>
      <Grid sx={styles.bgContainer}>
        <Grid sx={styles.contentGradient}>
          <Container maxWidth="lg" sx={styles.contentContainer}>
            <Typography variant="h2" gutterBottom sx={styles.contentTitle}>
              Наши преимущества
            </Typography>
            <Box sx={styles.contentInner}>
              <Advantages />
            </Box>
          </Container>
        </Grid>
      </Grid>
      <Container>
        <Divider sx={styles.devider} />
        <Box sx={styles.contentInner}>
          <ContactsInfo />
        </Box>
      </Container>
    </>
  );
}
