import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

type Props = {
  isTop: boolean;
  isHit: boolean;
}

export const Stickers = (props: Props) => {
  const theme = useTheme();
  const styles = {
    container: { display: "flex", flexDirection: "column", position: "absolute", top: "30px", left: 0, color: theme.palette.primary.contrastText },
    top: {
      alignItems: "center",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "0 12px 12px 0",
      marginRight: "auto",
      padding: "5px 10px 5px 5px",
      zIndex: 100
    },
    hit: {
      alignItems: "center",
      backgroundColor: theme.palette.primary.dark,
      borderRadius: "0 12px 12px 0",
      marginTop: "5px",
      marginRight: "auto",
      padding: "5px 10px 5px 5px",
      zIndex: 100
    },
  };
  const { isTop, isHit } = props;
  
  return (
    <Box sx={styles.container}>
      <Typography variant="body2" sx={styles.top} display={isTop ? "flex" : "none"}>Топ 10</Typography>
      <Typography variant="body2" sx={styles.hit} display={isHit ? "flex" : "none"}>Хит</Typography>
    </Box>
  );
};
