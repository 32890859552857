import { combineReducers, createAction, createReducer as reducer } from "@reduxjs/toolkit";

// types
type SettingsState = {
  pageSize: number;
  pageCount: number;
  pageIndex: number;
};

//action factory
const actionFactory = (() =>
  function <P = void>(key: string) {
    return createAction<P>(`@@features/portfolio/${key}`);
  })();

const settingsState: SettingsState = {
  pageSize: 36,
  pageCount: 1,
  pageIndex: 1,
};

const portfolio: Portfolio.ItemsTypes = [];

export type ChangeSettingsArgs = Partial<SettingsState>;

//actions
export const changeSettings = actionFactory<ChangeSettingsArgs>("settings/change");

export const fetchPorfolioList = actionFactory<Portfolio.ItemsTypes>("list");

//reducers
const settingsReducer = reducer(settingsState, (b) => {
  b.addCase(changeSettings, (state, { payload }) => ({
    ...state,
    ...payload,
  }));
});

const fetchPortfolioListReducer = reducer(portfolio, (b) => {
  b.addCase(fetchPorfolioList, (_s, { payload }) =>
    payload.map((item) => ({ ...item }))
  );
});


export const PortfolioReducer = combineReducers({
  portfolioList: fetchPortfolioListReducer,
  settings: settingsReducer,
});
